

export default [
    // *===============================================---*
    // *--------- lowstock  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/lowstock/list',
        name: 'lowstock-list',
        component: () => import('@/views/lowstock/main.vue'),
        meta: {
            pageTitle: 'Low Stock List',
            breadcrumb: [
                {
                    text: 'Purchase Orders List',
                    to: '/purchaseorders/list',
                },
                {
                    text: 'Low Stock List',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  
        },
    },
]