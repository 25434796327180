const state = {
    sales: [],
    finished: [],
    semi: [],
    raw: [],
    cost: [],
    production: [],
    lead: [],
    conversion: [],
    loading: false
};

export default state;