export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const REMOVE_CUSTOMERS = "REMOVE_CUSTOMERS";
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const GET_CUSTOMERSBYID = "GET_CUSTOMERSBYID";
export const GET_ORDERSBYID = "GET_ORDERSBYID";
export const GET_QUOTATIONSBYID = "GET_QUOTATIONSBYID";
export const GET_SERVICEREQUESTSBYID = "GET_SERVICEREQUESTSBYID";
export const GET_INVOICESBYID = "GET_INVOICESBYID";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const LOADING_QUOTATIONS = "LOADING_QUOTATIONS";
export const LOADING_SERVICEREQUESTS = "LOADING_SERVICEREQUESTS";
export const LOADING_INVOICES = "LOADING_INVOICES";
