import * as types from "./types";

const mutations = {
    [types.LOADING_SERVICEREQUESTS](state, value) {
        state.loading = value;
    },
    [types.GET_SERVICEREQUESTS_LIST](state, servicerequests) {
        state.servicerequests = servicerequests;
    },
    [types.REMOVE_SERVICEREQUESTS](state, id) {        
        state.servicerequests = state.servicerequests.filter((x) => x.id !== id);        
    },
    [types.ADD_SERVICEREQUESTS](state, servicerequests) {        
        state.servicerequests.unshift(servicerequests);
    },
    [types.UPDATE_SERVICEREQUESTS](state, servicerequests) {        
        const servicerequestsIndex = state.servicerequests.findIndex((x) => x.id === servicerequests.id);
        state.servicerequests[servicerequestsIndex] = servicerequests;        
    },
    [types.GET_SERVICEREQUESTSBYID](state, serviceOne) {        
        state.serviceOne = serviceOne;
    }
};

export default mutations;