import api from "@/api/api-v1-config";

export async function getRoleAxios() {    
    return await api.get("roles");
}

export async function deleteRoleAxios(id) {    
    return await api.delete("roles/" + id);
}

export async function postRoleAxios(item) {    
    return await api.post("roles", item);
}

export async function putRoleAxios(id, item) {    
    return await api.put("roles/" + id + "/permissions", item);
}