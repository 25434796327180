import * as types from "./types";
import { 
    getItemUOMListAxios, 
    deleteItemUOMAxios,
    postItemUOMAxios,
    putItemUOMAxios
} from "./services";

export async function getItemUOMListAction({commit}) {
    commit(types.LOADING_ITEMUOM, true);
    try {
        const {data} = await getItemUOMListAxios();
        commit(types.GET_ITEMUOM_LIST, data.result);
    }
    catch(e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMUOM, false);
    }
   
}

export async function removeItemUOMAction({commit}, payload) {
    commit(types.LOADING_ITEMUOM, true);
    try {
        await deleteItemUOMAxios(payload);
        commit(types.REMOVE_ITEMUOM, payload);
    }
    catch(e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMUOM, false);
    }
}

export async function addItemUOMAction({commit}, payload) {
    commit(types.LOADING_ITEMUOM, true);
    try {
        const {data} = await postItemUOMAxios(payload);
        payload.id = data.id;
        payload.contactPersons = [];
        commit(types.ADD_ITEMUOM, payload);
    }
    catch(e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMUOM, false);
    }
}

export async function updateItemUOMAction({commit}, payload) {
    commit(types.LOADING_ITEMUOM, true);
    try {
        await putItemUOMAxios(payload);        
        commit(types.UPDATE_ITEMUOM, payload);
    }
    catch(e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMUOM, false);
    }
}