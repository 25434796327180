import api from "@/api/api-v2-config";

export async function getInvoicesListAxios() {    
    return await api.get("invoices");
}

export async function getInvoicesIdAxios(id) {    
    return await api.get("invoices/"+ id);
}

export async function deleteInvoicesAxios(invoices) {    
    return await api.delete("invoices", invoices);
}

export async function postInvoicesAxios(invoices) {    
    return await api.post("invoices", invoices);
}

export async function putInvoicesAxios(invoices) {    
    return await api.put("invoices", invoices);
}