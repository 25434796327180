const getters = {
    sales: (state) => state.sales,
    finished: (state) => state.finished,
    semi: (state) => state.semi,
    raw: (state) => state.raw,
    cost: (state) => state.cost,
    production: (state) => state.production,
    lead: (state) => state.lead,
    conversion: (state) => state.conversion,
    loading: state => state.loading
};

export default getters;