import * as types from "./types";
import {
    getPushAxios,
    deletePushAxios,
    postPushAxios,
    putPushAxios,
    getPushIdAxios
} from "./services";

export async function getPushListAction({ commit }) {
    commit(types.LOADING_PUSH, true);
    try {
        const { data } = await getPushAxios();
        commit(types.GET_PUSH_LIST, data.result.data);
    }
    catch (e) {
        throw e;
    }
    finally{
        commit(types.LOADING_PUSH, false);
    }
}

export async function removePushAction({ commit }, payload) {
    commit(types.LOADING_PUSH, true);
    try {
        await deletePushAxios(payload);
        commit(types.REMOVE_PUSH, payload);
    }
    catch (e) {
        throw e;
    }
    finally{
        commit(types.LOADING_PUSH, false);
    }

}
export async function getPushByIdAction({ commit }, payload) {
    commit(types.LOADING_PUSH, true);
    try {
        const { data } = await getPushIdAxios(payload);
        commit(types.GET_PUSH_ID, data.result);
    }
    catch (e) {
        throw e;
    }
    finally{
        commit(types.LOADING_PUSH, false);
    }
}


export async function addPushAction({ commit }, payload) {
    commit(types.LOADING_PUSH, true);
    try {
        await postPushAxios(payload);
        commit(types.ADD_PUSH, payload);
    }
    catch (e) {
        throw e;
    }
    finally{
        commit(types.LOADING_PUSH, false);
    }
}

export async function updatePushAction({ commit }, payload) {
    commit(types.LOADING_PUSH, true);
    try {
        await putPushAxios(payload);
        commit(types.UPDATE_PUSH, payload);
    }
    catch (e) {
        throw e;
    }
    finally{
        commit(types.LOADING_PUSH, false);
    }
  
}