import api from "@/api/api-v2-config";

export async function getContactsListAxios() {    
    return await api.get("contactpersons?pageNumber=1&pageSize=10000000");
}

export async function deleteContactsAxios(id) {    
    return await api.delete("contactpersons/" + id);
}

export async function postContactsAxios(contactpersons) {    
    return await api.post("contactpersons", contactpersons);
}

export async function putContactsAxios(contactpersons) {    
    return await api.put("contactpersons", contactpersons);
}

export async function removeLeadContact(payload){    
    return await api.delete("contactpersons/deleteleadcontactperson", payload);
}


export async function makePrimary(payload){    
    return await api.post("contactpersons/setprimarycontact", payload);
}



