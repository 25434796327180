import api from "@/api/api-v2-config";

export async function getServiceRequestsListAxios() {    
    return await api.get("servicerequests?pageNumber=1&pageSize=1000");
}

export async function deleteServiceRequestsAxios(id) {    
    return await api.delete("servicerequests",id);
}

export async function postServiceRequestsAxios(servicerequests) {    
    return await api.post("servicerequests", servicerequests);
}

export async function putServiceRequestsAxios(servicerequests) {    
    return await api.put("servicerequests", servicerequests);
}

export async function getServiceRequestsByIDAxios(id) {    
    return await api.get("servicerequests/" + id);
}

