import * as types from "./types";
import {
    getInvoicesListAxios,
    deleteInvoicesAxios,
    postInvoicesAxios,
    putInvoicesAxios,
    getInvoicesIdAxios
} from "./services";

export async function getInvoicesListAction({ commit }) {
    commit(types.LOADING_INVOICES, true);
    try {
        const { data } = await getInvoicesListAxios();
        commit(types.GET_INVOICES_LIST, data.result);
    }
    catch (e) {
       
        throw e;
    }
    commit(types.LOADING_INVOICES, false);
}

export async function getInvoiceIdAction({ commit }, payload) {
    commit(types.LOADING_INVOICES, true);
    try {
        const { data } = await getInvoicesIdAxios(payload);
        commit(types.GET_INVOICES_ID, data.result);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_INVOICES, false);
}

export async function removeInvoicesAction({ commit }, payload) {
    commit(types.LOADING_INVOICES, true);
    try {
        await deleteInvoicesAxios(payload);
        commit(types.REMOVE_INVOICES, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_INVOICES, false);
}

export async function addInvoicesAction({ commit }, payload) {
    commit(types.LOADING_INVOICES, true);
    try {
        const response = await postInvoicesAxios(payload);
        commit(types.ADD_INVOICES, payload);
        return response;
    }
    catch (e) {
  
        throw e;
    }
    finally{
        commit(types.LOADING_INVOICES, false);
    }
}

export async function updateInvoicesAction({ commit }, payload) {
    commit(types.LOADING_INVOICES, true);
    try {
        await putInvoicesAxios(payload);
        commit(types.UPDATE_INVOICES, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_INVOICES, false);
}