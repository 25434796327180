export default [
    // *===============================================---*
    // *--------- Warehouse  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/warehouse/warehouse-list',
        name: 'warehouse-list',
        component: () => import('@/views/inventory/warehouse/ClientsList.vue'),
        meta: {
            pageTitle: 'Warehouses List',
            breadcrumb: [
                {
                    text: 'Warehouses List',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  
        },
    },
]