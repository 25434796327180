export default [
    // *===============================================---*
    // *--------- Supplier  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/supplier/supplier-list',
        name: 'supplier-list',
        component: () => import('@/views/inventory/supplier/ClientsList.vue'),
        meta: {
            pageTitle: 'Suppliers List',
            breadcrumb: [
                {
                    text: 'Suppliers List',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  
        },
    },
]