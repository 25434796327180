import * as types from "./types";

import {
    getQuotationsListAxios,
    deleteQuotationsAxios,
    postQuotationsAxios,
    putQuotationsAxios,
    getQuotationByIdAxios
} from "./services";

export async function getQuotationsListAction({ commit }) {
    commit(types.LOADING_QUOTATIONS, true);
    try {
        const { data } = await getQuotationsListAxios();
        commit(types.GET_QUOTATIONS_LIST, data.result);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_QUOTATIONS, false);
    }
}

export async function removeQuotationsAction({ commit }, payload) {
    commit(types.LOADING_QUOTATIONS, true);
    try {
        await deleteQuotationsAxios(payload);
        commit(types.REMOVE_QUOTATIONS, payload);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_QUOTATIONS, false);
    }
}

export async function addQuotationsAction({ commit }, payload) {
    commit(types.LOADING_QUOTATIONS, true);
    try {
        const { data } = await postQuotationsAxios(payload);
        commit(types.ADD_QUOTATIONS, data.result);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_QUOTATIONS, false);
    }
}

export async function updateQuotationsAction({ commit }, payload) {
    commit(types.LOADING_QUOTATIONS, true);
    try {
        await putQuotationsAxios(payload);
        commit(types.UPDATE_QUOTATIONS, payload);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_QUOTATIONS, false);
    }
}

export async function getQuotationByIdAction({ commit }, payload) {
    commit(types.LOADING_QUOTATIONS, true);
    try {
        const { data } = await getQuotationByIdAxios(payload);
        commit(types.GET_QUOTATION_ID, data.result);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_QUOTATIONS, false);
    }
}