import * as types from "./types";

const mutations = {
    [types.LOADING_SALESPERSON](state, value) {
        state.loading = value;
    },
    [types.GET_SALESPERSON_LIST](state, salesperson) {
        state.salesperson = salesperson;
    }
};

export default mutations;