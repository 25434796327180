import * as types from "./types";
import { 
    getTaskAxios, 
    deleteTaskAxios,
    postTaskAxios,
    putTaskAxios
} from "./services";

export async function getTaskListAction({commit}) {
    commit(types.LOADING_TASK, true);
    try {
        const {data} = await getTaskAxios();
        commit(types.GET_TASK_LIST, data.result);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

    commit(types.LOADING_TASK, false);
    }
}

export async function removeTaskAction({commit}, payload) {
    commit(types.LOADING_TASK, true);
    try {
        await deleteTaskAxios(payload);
        commit(types.REMOVE_TASK, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

        commit(types.LOADING_TASK, false);
        }
}

export async function addTaskAction({commit}, payload) {
    commit(types.LOADING_TASK, true);
    try {
        await postTaskAxios(payload);
        commit(types.ADD_TASK, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

        commit(types.LOADING_TASK, false);
        }
}

export async function updateTaskAction({commit}, payload) {
    commit(types.LOADING_TASK, true);
    try {
        await putTaskAxios(payload);        
        commit(types.UPDATE_TASK, payload);
    }
    catch(e) {
        throw e;
    }
    finally {

        commit(types.LOADING_TASK, false);
        }
}