export const LOADING_REPORT = "LOADING_REPORT";
export const GET_SALESREPORT = "GET_SALESREPORT";
export const GET_FINISHED = "GET_FINISHED";
export const GET_SEMI_FINISHED = "GET__SEMI_FINISHED";
export const GET_RAW = "GET_RAW";
export const GET_COST = "GET_COST";
export const GET_PRODUCTION = "GET_PRODUCTION";
export const GET_LEADTIME = "GET_LEADTIME";
export const GET_LEAD_CONVERSION = "GET_LEAD_CONVERSION";

