import * as types from "./types";

const mutations = {
    [types.LOADING_TASK](state, value) {
        state.loading = value;
    },
    [types.GET_TASK_LIST](state, task) {
        state.task = task;
    },
    [types.REMOVE_TASK](state, id) {        
        state.task = state.task.filter((x) => x.id !== id);        
    },
    [types.ADD_TASK](state, task) {        
        state.task.unshift(task);
    },
    [types.UPDATE_TASK](state, task) {        
        const TaskIndex = state.task.findIndex((x) => x.id === task.id);
        state.task[TaskIndex] = task;        
    }
};

export default mutations;