import * as types from "./types";

const mutations = {
    [types.LOADING_SUPPLIER](state, value) {
        state.loading = value;
    },
    [types.GET_SUPPLIER_LIST](state, suppliers) {
        state.suppliers = suppliers;
    },
    [types.REMOVE_SUPPLIER](state, id) {        
        state.suppliers = state.suppliers.filter((x) => x.id !== id);        
    },
    [types.ADD_SUPPLIER](state, supplier) {        
        state.suppliers.unshift(supplier);
    },
    [types.UPDATE_SUPPLIER](state, supplier) {        
        const supplierIndex = state.suppliers.findIndex((x) => x.id === supplier.id);
        state.suppliers[supplierIndex] = supplier;        
    },
    [types.GET_SUPPLIERBYID](state, supplierId) {
        state.supplierId = supplierId;
    }
};

export default mutations;