import * as types from "./types";

const mutations = {
    [types.LOADING_NOTIFICATION](state, value) {
        state.loading = value;
    },
    [types.GET_NOTIFICATION](state, notification) {
        state.notification = notification;
    },
    
};

export default mutations;