import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "LEADS 1",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: 'John Doe',
      status: 'Converted',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'LEADS 2',
      email: 'bcoulman1@yolasite.com',
      startDate: '05/20/2022',
      owner: 'John Doe',
      status: 'Active',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'LEADS 3 ',
      email: 'sganderton2@tuttocitta.it',
      startDate: '03/24/2022',
      owner: 'John Doe',
      status: 'Converted',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      Company: 'LEADS 4',
      email: 'dcrossman3@google.co.jp',
      startDate: '12/03/2022',
      owner: 'John Doe',
      status: 'Opportunity',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      Company: 'LEADS 5',
      email: 'hnisius4@gnu.org',
      startDate: '08/25/2022',
      owner: 'John Doe',
      status: 'Converted',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      Company: 'LEADS 6 ',
      email: 'ghoneywood5@narod.ru',
      startDate: '06/01/2022',
      owner: 'John Doe',
      status: 'Quotation',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      Company: 'LEADS 7 ',
      email: 'ediehn6@163.com',
      startDate: '10/15/2022',
      owner: 'John Doe',
      status: 'Converted',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'LEADS 8',
      email: 'raldren7@mtv.com',
      startDate: '11/05/2022',
      owner: 'John Doe',
      status: 'Lost',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      Company: 'LEADS 9 ',
      email: 'amoakler8@shareasale.com',
      startDate: '12/29/2022',
      owner: 'John Doe',
      status: 'Opportunity',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      Company: 'LEADS 10',
      email: 'mpenhalewick9@php.net',
      startDate: '04/19/2022',
      owner: 'John Doe',
      status: 'Converted',
    },
  ],

  tableClient: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "CUSTOMER 1",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 1120',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'CUSTOMER 2',
      email: 'bcoulman1@yolasite.com',
      startDate: '05/20/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 6465',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'CUSTOMER 3 ',
      email: 'sganderton2@tuttocitta.it',
      startDate: '03/24/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 655',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      Company: 'CUSTOMER 4',
      email: 'dcrossman3@google.co.jp',
      startDate: '12/03/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 54124',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      Company: 'CUSTOMER 5',
      email: 'hnisius4@gnu.org',
      startDate: '08/25/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 12512',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      Company: 'CUSTOMER 6 ',
      email: 'ghoneywood5@narod.ru',
      startDate: '06/01/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 55485',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      Company: 'CUSTOMER 7 ',
      email: 'ediehn6@163.com',
      startDate: '10/15/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 22597',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'CUSTOMER 8',
      email: 'raldren7@mtv.com',
      startDate: '11/05/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 20679',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      Company: 'CUSTOMER 9 ',
      email: 'amoakler8@shareasale.com',
      startDate: '12/29/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 50260',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      Company: 'CUSTOMER 10',
      email: 'mpenhalewick9@php.net',
      startDate: '04/19/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 84025',
    },
  ],

  tableSupplier: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "SUPPLIER 1",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 1120',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'SUPPLIER 2',
      email: 'bcoulman1@yolasite.com',
      startDate: '05/20/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 6465',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'SUPPLIER 3 ',
      email: 'sganderton2@tuttocitta.it',
      startDate: '03/24/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 655',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      Company: 'SUPPLIER 4',
      email: 'dcrossman3@google.co.jp',
      startDate: '12/03/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 54124',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      Company: 'SUPPLIER 5',
      email: 'hnisius4@gnu.org',
      startDate: '08/25/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 12512',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      Company: 'SUPPLIER 6 ',
      email: 'ghoneywood5@narod.ru',
      startDate: '06/01/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 55485',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      Company: 'SUPPLIER 7 ',
      email: 'ediehn6@163.com',
      startDate: '10/15/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 22597',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'SUPPLIER 8',
      email: 'raldren7@mtv.com',
      startDate: '11/05/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 20679',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      Company: 'SUPPLIER 9 ',
      email: 'amoakler8@shareasale.com',
      startDate: '12/29/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 50260',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      Company: 'SUPPLIER 10',
      email: 'mpenhalewick9@php.net',
      startDate: '04/19/2022',
      owner: 'John Doe',
      status: 'Converted',
      revenue: 'RS 84025',
    },
  ],


  tableLabour: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "Machinist",
      email: 'Port Louis',
      startDate: 'Team 1',
      owner: '34',
      status: 'Converted',
      revenue: 'RS 1120',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'Clerk',
      email: 'Curepipe',
      startDate: 'Team 1',
      owner: '4',
      status: 'Converted',
      revenue: 'RS 6465',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'Quality Inspector ',
      email: 'Jinfei',
      startDate: 'Team 1',
      owner: '34',
      status: 'Converted',
      revenue: 'RS 655',
    },
  ],

  tableEquipment: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "Equipment 1",
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      MaintainanceDate: '08/25/2022',
      owner: 'FGG206',
      status: 'Converted',
      revenue: '08/25/2022',
      location: 'Port Louis',
      available: 'Booked',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'Equipment 2',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'FGG2062',
      status: 'Converted',
      revenue: '08/25/2022',
      MaintainanceDate: '08/25/2022',
      location: 'Port Louis',
      available: 'Available',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'Equipment 3 ',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'BGU12896',
      status: 'Converted',
      revenue: '08/25/2022',
      MaintainanceDate: '08/25/2022',
      location: 'Port Louis',
      available: 'Maintenance',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      Company: 'Equipment 4',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'H6U12896',
      status: 'Converted',
      revenue: '08/25/2022',
      MaintainanceDate: '08/25/2022',
      location: 'Port Louis',
      available: 'Maintenance',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      Company: 'Equipment 5',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'MOP2079',
      status: 'Converted',
      revenue: '08/25/2022',
      location: 'Port Louis',
      MaintainanceDate: '08/25/2022',
      available: 'Booked',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      Company: 'Equipment 6 ',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'MPPO219',
      status: 'Converted',
      revenue: '08/25/2022',
      location: 'Port Louis',
      MaintainanceDate: '08/25/2022',
      available: 'Booked',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      Company: 'Equipment 7 ',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'Team Leader',
      status: 'Converted',
      revenue: '08/25/2022',
      MaintainanceDate: '08/25/2022',
      location: 'Port Louis',
      available: 'Booked',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'Equipment 8',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'Exec',
      status: 'Converted',
      MaintainanceDate: '08/25/2022',
      revenue: '08/25/2022',
      location: 'Port Louis',
      available: 'Available',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      Company: 'Equipment 9',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'Relation Officer',
      status: 'Converted',
      revenue: '08/25/2022',
      location: 'Port Louis',
      available: 'Available',
      MaintainanceDate: '08/25/2022',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      Company: 'Equipment 10',
      email: 'Let’s say a large corporation owns a large facility with several buildings and a large parking lot that the employees use to park their vehicles.',
      startDate: '08/25/2022',
      owner: 'Relation Officer',
      status: 'Converted',
      MaintainanceDate: '08/25/2022',
      revenue: '08/25/2022',
      location: 'Port Louis',
      available: 'Available',
    },
  ],

  tableProduct: [
    {
      id: 1,
      Company: "Sales Order 1",
      description: 'Product 1',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '58',
      cost: 'Finished Good',
      sell: 'RS 5894',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    {
      id: 1,
      Company: "Sales Order 2",
      description: 'Product 1',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '58',
      cost: 'Work In Progress',
      sell: 'RS 5894',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    {
      id: 1,
      Company: "Sales Order 3",
      description: 'Product 1',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '58',
      cost: 'Work In Progress',
      sell: 'RS 5894',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    
  ],


  tableNewProduct: [
    {
      id: 1,
      Company: "Product 1",
      description: 'Product 1',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '58',
      cost: 'Finished Good',
      sell: 'RS 5894',
      uom: 'KG',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    {
      id: 1,
      Company: "Product 2",
      description: 'Product 1',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '58',
      uom: 'Litres',
      cost: 'Work In Progress',
      sell: 'RS 5894',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    {
      id: 1,
      Company: "Product 3",
      description: 'Product 1',
      startDate: '09/23/2022',
      uom: 'Metres',
      reOrderDate: '50',
      owner: '58',
      cost: 'Work In Progress',
      sell: 'RS 5894',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    
  ],

  

  tableRaw: [
    {
      id: 1,
      Company: "Raw Material 1",
      description: 'metal, any of a class of substances characterized by high electrical and thermal conductivity as well as by malleability, ductility, and high reflectivity of light.',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '58',
      cost: 'RS 1120',
      sell: 'Supplier 1',
      status: 'Converted',
      category: 'Category 4',
      revenue: 'RS 1120',
    },
    {
      id: 2,
      Company: "Raw Material 2",
      description: 'metal, any of a class of substances characterized by high electrical and thermal conductivity as well as by malleability, ductility, and high reflectivity of light.',
      startDate: '03/23/2022',
      reOrderDate: '15',
      owner: '84',
      cost: 'RS 120',
      sell: 'Supplier 2',
      status: 'Converted',
      category: 'Category 1',
      revenue: 'RS 1120',
    },
    {
      id: 3,
      Company: "Raw Material 3",
      description: 'metal, any of a class of substances characterized by high electrical and thermal conductivity as well as by malleability, ductility, and high reflectivity of light.',
      startDate: '06/10/2022',
      reOrderDate: '74',
      owner: '90',
      cost: 'RS 5820',
      sell: 'Supplier 3',
      status: 'Converted',
      category: 'Category 3',
      revenue: 'RS 1120',
    },
    {
      id: 4,
      Company: "Raw Material 4",
      description: 'metal, any of a class of substances characterized by high electrical and thermal conductivity as well as by malleability, ductility, and high reflectivity of light.',
      startDate: '09/23/2022',
      reOrderDate: '98',
      owner: '0',
      cost: 'RS 1120',
      category: 'Category 2',
      sell: 'Supplier 4',
      status: 'Converted',
      revenue: 'RS 1120',
    },
  ],

  tableBOM: [
    {
      id: 1,
      Company: "Product 1",
      description: 'Bill of Material 1 ',
      startDate: '09/23/2022',
      reOrderDate: '50',
      owner: '1',
      cost: 'RS 1120',
      sell: 'Supplier 1',
      uom: 'KG',
      status: 'Converted',
      category: '1',
      revenue: 'RS 1120',
    },
    {
      id: 2,
      Company: "Product 1",
      description: 'Bill of Material 2',  
      startDate: '03/23/2022',
      reOrderDate: '15',
      owner: '3',
      cost: 'RS 120',
      sell: 'Supplier 2',
      uom: 'gram',
      status: 'Converted',
      category: '2',
      revenue: 'RS 1120',
    },
    {
      id: 3,
      Company: "Product 1",
      description: 'Bill of Material 3',
      startDate: '06/10/2022',
      reOrderDate: '74',
      owner: '2',
      cost: 'RS 5820',
      sell: 'Supplier 3',
      status: 'Converted',
      category: '6',
      uom: 'KG',
      revenue: 'RS 1120',
    },
    {
      id: 4,
      Company: "Product 1",
      description: 'Bills of Material 4',
      startDate: '09/23/2022',
      reOrderDate: '98',
      owner: '6',
      cost: 'RS 1120',
      category: '7',
      uom: 'Litre',
      sell: 'Supplier 4',
      status: 'Converted',
      revenue: 'RS 1120',
    },
    {
      id: 4,
      Company: "Product 1",
      description: 'Bills of Material 5',
      startDate: '09/23/2022',
      reOrderDate: '98',
      owner: '6',
      uom: 'Litre',
      cost: 'RS 1120',
      category: '8',
      sell: 'Supplier 4',
      status: 'Converted',
      revenue: 'RS 1120',
    },
  ],

  tableContact : [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "John Doe",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: 'John Doe',
      phone:'59917415',
      status: 'Converted',
      jobTitle:'CEO',
    },
  ],
  tablePO : [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "#5150",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: 'John Doe',
      status: 'Open',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "#5151",
      email: 'kocrevywet0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: 'Deelam Baichu',
      status: 'Cancelled',
    },
  ],

  tableRowGrp: [
    {
      mode: 'span',
      label: 'Accountant',
      html: false,
      children: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/10-small.png'),
          Company: "Korrie O'Crevy",
          email: 'kocrevy0@thetimes.co.uk',
          startDate: '09/23/2022',
          owner: '$23896.35',
          experience: '1 Year',
          status: 'Converted',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Actuary',
      children: [
        {
          id: 2,
          avatar: require('@/assets/images/avatars/1-small.png'),
          Company: 'Bailie Coulman',
          email: 'bcoulman1@yolasite.com',
          startDate: '05/20/2022',
          owner: '$13633.69',
          status: 'Quotation',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Administrative Assistan',
      children: [
        {
          id: 3,
          avatar: require('@/assets/images/avatars/9-small.png'),
          Company: 'Stella Ganderton',
          email: 'sganderton2@tuttocitta.it',
          startDate: '03/24/2022',
          owner: '$13076.28',
          status: 'Opportunity',
        },
        {
          id: 4,
          avatar: require('@/assets/images/avatars/3-small.png'),
          Company: 'Dorolice Crossman',
          email: 'dcrossman3@google.co.jp',
          startDate: '12/03/2022',
          owner: '$12336.17',
          status: 'Converted',
        },
        {
          id: 5,
          avatar: require('@/assets/images/avatars/4-small.png'),
          Company: 'Harmonia Nisius',
          email: 'hnisius4@gnu.org',
          startDate: '08/25/2022',
          owner: '$10909.52',
          status: 'Active',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Analog Circuit Design manager',
      children: [
        {
          id: 6,
          avatar: require('@/assets/images/avatars/5-small.png'),
          Company: 'Genevra Honeywood',
          email: 'ghoneywood5@narod.ru',
          startDate: '06/01/2022',
          owner: '$17803.8',
          experience: '1 Year',
          status: 'Quotation',
        },
        {
          id: 7,
          avatar: require('@/assets/images/avatars/7-small.png'),
          Company: 'Eileen Diehn',
          email: 'ediehn6@163.com',
          startDate: '10/15/2022',
          owner: '$18991.67',
          status: 'Converted',
        },
      ],
    },
    {
      mode: 'span',
      label: 'Analyst Programmer',
      children: [
        {
          id: 8,
          avatar: require('@/assets/images/avatars/9-small.png'),
          Company: 'Richardo Aldren',
          email: 'raldren7@mtv.com',
          startDate: '11/05/2022',
          owner: '$19230.13',
          status: 'Lost',
        },
        {
          id: 9,
          avatar: require('@/assets/images/avatars/2-small.png'),
          Company: 'Allyson Moakler',
          email: 'amoakler8@shareasale.com',
          startDate: '12/29/2022',
          owner: '$11677.32',
          status: 'Opportunity',
        },
        {
          id: 10,
          avatar: require('@/assets/images/avatars/6-small.png'),
          Company: 'Merline Penhalewick',
          email: 'mpenhalewick9@php.net',
          startDate: '04/19/2022',
          owner: '$15939.52',
          status: 'Converted',
        },
      ],
    },
  ],

  tableAdvancedSearch: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "Korrie O'Crevy",
      post: 'Nuclear Power Engineer',
      email: 'kocrevy0@thetimes.co.uk',
      city: 'Krasnosilka',
      startDate: '09/23/2022',
      owner: '$23896.35',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'Bailie Coulman',
      post: 'VP Quality Control',
      email: 'bcoulman1@yolasite.com',
      city: 'Hinigaran',
      startDate: '05/20/2022',
      owner: '$13633.69',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'Stella Ganderton',
      post: 'Operator',
      email: 'sganderton2@tuttocitta.it',
      city: 'Golcowa',
      startDate: '03/24/2022',
      owner: '$13076.28',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      Company: 'Dorolice Crossman',
      post: 'Cost Accountant',
      email: 'dcrossman3@google.co.jp',
      city: 'Paquera',
      startDate: '12/03/2022',
      owner: '$12336.17',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      Company: 'Harmonia Nisius',
      post: 'Senior Cost Accountant',
      email: 'hnisius4@gnu.org',
      city: 'Lucan',
      startDate: '08/25/2022',
      owner: '$10909.52',
    },
    {
      id: 6,
      avatar: require('@/assets/images/avatars/5-small.png'),
      Company: 'Genevra Honeywood',
      post: 'Geologist',
      email: 'ghoneywood5@narod.ru',
      city: 'Maofan',
      startDate: '06/01/2022',
      owner: '$17803.8',
    },
    {
      id: 7,
      avatar: require('@/assets/images/avatars/7-small.png'),
      Company: 'Eileen Diehn',
      post: 'Environmental Specialist',
      email: 'ediehn6@163.com',
      city: 'Lampuyang',
      startDate: '10/15/2022',
      owner: '$18991.67',
    },
    {
      id: 8,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'Richardo Aldren',
      post: 'Senior Sales Associate',
      email: 'raldren7@mtv.com',
      city: 'Skoghall',
      startDate: '11/05/2022',
      owner: '$19230.13',
    },
    {
      id: 9,
      avatar: require('@/assets/images/avatars/2-small.png'),
      Company: 'Allyson Moakler',
      post: 'Safety Technician',
      email: 'amoakler8@shareasale.com',
      city: 'Mogilany',
      startDate: '12/29/2022',
      owner: '$11677.32',
    },
    {
      id: 10,
      avatar: require('@/assets/images/avatars/6-small.png'),
      Company: 'Merline Penhalewick',
      post: 'Junior Executive',
      email: 'mpenhalewick9@php.net',
      city: 'Kanuma',
      startDate: '04/19/2022',
      owner: '$15939.52',
    },
  ],
  tableSsr: [
    {
      id: 1,
      avatar: require('@/assets/images/avatars/10-small.png'),
      Company: "Korrie O'Crevy",
      email: 'kocrevy0@thetimes.co.uk',
      startDate: '09/23/2022',
      owner: '$23896.35',
      status: 'Converted',
    },
    {
      id: 2,
      avatar: require('@/assets/images/avatars/1-small.png'),
      Company: 'Bailie Coulman',
      email: 'bcoulman1@yolasite.com',
      startDate: '05/20/2022',
      owner: '$13633.69',
      status: 'Active',
    },
    {
      id: 3,
      avatar: require('@/assets/images/avatars/9-small.png'),
      Company: 'Stella Ganderton',
      email: 'sganderton2@tuttocitta.it',
      startDate: '03/24/2022',
      owner: '$13076.28',
      status: 'Converted',
    },
    {
      id: 4,
      avatar: require('@/assets/images/avatars/3-small.png'),
      Company: 'Dorolice Crossman',
      email: 'dcrossman3@google.co.jp',
      startDate: '12/03/2022',
      owner: '$12336.17',
      status: 'Opportunity',
    },
    {
      id: 5,
      avatar: require('@/assets/images/avatars/4-small.png'),
      Company: 'Harmonia Nisius',
      email: 'hnisius4@gnu.org',
      startDate: '08/25/2022',
      owner: '$10909.52',
      status: 'Converted',
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/good-table/basic').reply(() => [200, data.tableBasic])
mock.onGet('/good-table/tableContact').reply(() => [200, data.tableContact])
mock.onGet('/good-table/tableClient').reply(() => [200, data.tableClient])
mock.onGet('/good-table/tableProduct').reply(() => [200, data.tableProduct])
mock.onGet('/good-table/tableSupplier').reply(() => [200, data.tableSupplier])
mock.onGet('/good-table/tableRaw').reply(() => [200, data.tableRaw])
mock.onGet('/good-table/tableLabour').reply(() => [200, data.tableLabour])
mock.onGet('/good-table/tableBOM').reply(() => [200, data.tableBOM])
mock.onGet('/good-table/tableNewProduct').reply(() => [200, data.tableNewProduct])
mock.onGet('/good-table/tablePO').reply(() => [200, data.tablePO])
mock.onGet('/good-table/tableEquipment').reply(() => [200, data.tableEquipment])
mock.onGet('/good-table/row-group').reply(() => [200, data.tableRowGrp])
mock.onGet('/good-table/advanced-search').reply(() => [200, data.tableAdvancedSearch])
mock.onGet('/good-table/table_ssr').reply(() => [200, data.tableSsr])
