import * as types from "./types";

const mutations = {
    [types.LOADING_REPORT](state, value) {
        state.loading = value;
    },
    [types.GET_SALESREPORT](state, sales) {
        state.sales = sales;
    },
    [types.GET_FINISHED](state, finished) {
        state.finished = finished;
    },
    [types.GET_SEMI_FINISHED](state, semi) {
        state.semi = semi;
    },
    [types.GET_RAW](state, raw) {
        state.raw = raw;
    },
    [types.GET_COST](state, cost) {
        state.cost = cost;
    },
    [types.GET_PRODUCTION](state, production) {
        state.production = production;
    },
    [types.GET_LEADTIME](state, lead) {
        state.lead = lead;
    },
    [types.GET_LEAD_CONVERSION](state, conversion) {
        state.conversion = conversion;
    }
};

export default mutations;