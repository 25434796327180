import * as types from "./types";
import { 
    getLabourAxios, 
    deleteLabourAxios,
    postLabourAxios,
    putLabourAxios
} from "./services";

export async function getLabourListAction({commit}) {
    commit(types.LOADING_LABOUR, true);
    try {
        const {data} = await getLabourAxios();
        commit(types.GET_LABOUR_LIST, data.result.data);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_LABOUR, false);
}

export async function removeLabourAction({commit}, payload) {
    commit(types.LOADING_LABOUR, true);
    try {
        await deleteLabourAxios(payload);
        commit(types.REMOVE_LABOUR, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_LABOUR, false);
    }
  
}

export async function addLabourAction({commit}, payload) {
    commit(types.LOADING_LABOUR, true);
    try {
        await postLabourAxios(payload);
        commit(types.ADD_LABOUR, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_LABOUR, false);
}

export async function updateLabourAction({commit}, payload) {
    commit(types.LOADING_LABOUR, true);
    try {
        await putLabourAxios(payload);        
        commit(types.UPDATE_LABOUR, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_LABOUR, false);
}