import * as types from "./types";

const mutations = {
    [types.LOADING_PUSH](state, value) {
        state.loading = value;
    },
    [types.GET_PUSH_LIST](state, push) {
        state.push = push;
    },
    [types.REMOVE_PUSH](state, id) {        
        state.push = state.push.filter((x) => x.id !== id);        
    },
    [types.ADD_PUSH](state, push) {        
        state.push.unshift(push);
    },
    [types.UPDATE_PUSH](state, push) {        
        const PushIndex = state.push.findIndex((x) => x.id === push.id);
        state.push[PushIndex] = push;        
    },
    [types.GET_PUSH_ID](state, pushId) {         
        state.pushId = pushId;    
    },
};

export default mutations;