import api from "@/api/api-v2-config";

export async function getJobcardListAxios() {    
    return await api.get("jobcards?pageNumber=1&pageSize=27");
}

export async function getJobcardIdAxios(id) {    
    return await api.get("jobcards/" + id);
}


export async function getJobcardDemandAxios() {    
    return await api.get("jobcards/demand");
}


export async function deleteJobcardAxios(Jobcards) {    
    return await api.delete("jobcards", Jobcards);
}

export async function postJobcardAxios(Jobcards) {    
    return await api.post("jobcards", Jobcards);
}

export async function putJobcardAxios(Jobcards) {    
    return await api.put("jobcards", Jobcards);
}

export async function putJobcardStatusAxios(Jobcards) {    
    return await api.put("jobcards/status", Jobcards);
}

export async function putMultipleJobCard(Jobcards) {    
    return await api.put("jobcards/liststatus", Jobcards);
}