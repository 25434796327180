import * as types from "./types";

const mutations = {
    [types.LOADING_WAREHOUSE](state, value) {
        state.loading = value;
    },
    [types.GET_WAREHOUSE_LIST](state, warehouse) {
        state.warehouse = warehouse;
    },
    [types.REMOVE_WAREHOUSE](state, id) {        
        state.warehouse = state.warehouse.filter((x) => x.id !== id);        
    },
    [types.ADD_WAREHOUSE](state, warehouse) {        
        state.warehouse.unshift(warehouse);
    },
    [types.UPDATE_WAREHOUSE](state, warehouse) {        
        const WarehouseIndex = state.warehouse.findIndex((x) => x.id === warehouse.id);
        state.warehouse[WarehouseIndex] = warehouse;        
    }
};

export default mutations;