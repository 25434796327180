import * as types from "./types";
import { 
    getNotificationAxios, 
} from "./services";

export async function getNotificationsAction({commit}) {
    commit(types.LOADING_NOTIFICATION, true);
    try {
        const {data} = await getNotificationAxios();
        commit(types.GET_NOTIFICATION, data.result);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_NOTIFICATION, false);
}

