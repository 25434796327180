export const LOADING_LEAD = "LOADING_LEAD";
export const GET_LEAD_LIST = "GET_LEAD_LIST";
export const REMOVE_LEAD = "REMOVE_LEAD";
export const ADD_LEAD = "ADD_LEAD";
export const UPDATE_LEAD = "UPDATE_LEAD";
export const GET_LEADBYID = "GET_LEADBYID";
export const GET_LEAD_QUOTATIONS = "GET_LEAD_QUOTATIONS";
export const GET_LEAD_CONTACT_PERSON = "GET_LEAD_CONTACT_PERSON";
export const GET_LEAD_CONVERT = "GET_LEAD_CONVERT";


