export default [

    // *===============================================---*
    // *--------- Calender - Marketing  ---------------------------------------*
    // *===============================================---*
    {
        path: '/marketing/plan/calender',
        name: 'marketing-plan-calender',
        component: () => import('@/views/marketing/calendar/Calendar.vue'),
        meta:{
            pageTitle:'Marketing Plan',
            breadcrumb: [
                {
                    text: 'Marketing Plan',
                    active:true,
                },
            ],
            permission: 'Permissions.Marketing.View'
        },
    },
    // *===============================================---*
    // *--------- Blog List Content - Marketing  ---------------------------------------*
    // *===============================================---*
    {
        path: '/marketing/blog/list',
        name: 'marketing-blog-list',
        component: () => import('@/views/marketing/blog/BlogList.vue'),
        meta: {
            pageTitle: 'Marketing Feed',
            breadcrumb: [
                {
                    text: 'Marketing Content',
                    active: true,
                },
              
            ],
            permission: 'Permissions.Marketing.View'
        },
        
    },

    // *===============================================---*
    // *--------- Blog Add Content - Marketing  ---------------------------------------*
    // *===============================================---*

    {
        path: '/marketing/blog/add',
        name: 'marketing-blog-add',
        component: () => import('@/views/marketing/blog/BlogAdd.vue'),
        meta: {
            pageTitle: 'Marketing Add Content',
            breadcrumb: [
                {
                    text: 'Marketing Content',
                    to: '/marketing/blog/list',
                },
                {
                    text: 'Add Content',
                    active: true,
                },
            ],
            permission: 'Permissions.Marketing.View'
        },
    },

    // *===============================================---*
    // *--------- CHAT - Marketing  ---------------------------------------*
    // *===============================================---*
    {
        path: '/marketing/chat',
        name: 'marketing-chat',
        component: () => import('@/views/marketing/chat/Chat.vue'),
        meta: {
            pageTitle: 'Marketing Chat',
            breadcrumb: [
             
                {
                    text: 'Conversations',
                    active: true,
                },
            ],
            permission: 'Permissions.Marketing.View'
    
          
        },
       
    },

]