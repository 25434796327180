import * as types from "./types";
import {
    getAppIdAxios,
    postAppIdAxios,
    pagePosts,
    deletePosts
} from "./services";

export async function getAppIdAction({ commit }) {
    commit(types.LOADING_MARKETING, true);
    try {
        const { data } = await getAppIdAxios();
        commit(types.GET_MARKETING, data.result);
    }
    catch (e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_MARKETING, false);
    }
}


export async function postAppIdAction({ commit }, payload) {
    commit(types.LOADING_MARKETING, true);
    try {
        await postAppIdAxios(payload);
        commit(types.POST_MARKETING_ID, payload);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_MARKETING, false);
    }
}



export async function getPostAction({ commit }, token) {
    commit(types.LOADING_MARKETING, true);
    try {
        const { data } = await pagePosts(token);
        commit(types.GET_POSTS, data.result.data);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_MARKETING, false);
    }
}


export async function removePostAction({ commit }, token, id) {
    commit(types.LOADING_MARKETING, true);
    try {
        await deletePosts(token, id); // Pass the id parameter here
        commit(types.REMOVE_POST, id);
    }
    catch (e) {
        throw e;
    }
    finally {

        commit(types.LOADING_MARKETING, false);

    }
}