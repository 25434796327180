import api from "@/api/api-v2-config";



export async function deletePayment(id) {    
    return await api.delete("payments", id);
}

export async function postPayment(payment) {    
    return await api.post("payments", payment);
}



export async function getPayment(id) {    
    return await api.get("payments/" + id);
}
