export default [

    // *===============================================---*
    // *--------- CUSTOMERS ------------------------------*
    // *===============================================---*
    {
        path: '/customer/customer-add',
        name: 'customer-add',
        component: () => import('@/views/sales/customers/Add/Main.vue'),
        meta: {
            pageTitle: 'Add Customer',
            breadcrumb: [
                {
                    text: 'Customers List',
                    to: '/customer/customer-list',
                },
                {
                    text: 'Add Customer',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
    {
        path: '/customer/customer-list',
        name: 'customer-list',
        component: () => import('@/views/sales/customers/ClientsList.vue'),
        meta: {
            pageTitle: 'Customers List',
            breadcrumb: [
                {
                    text: 'Customers List',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
    {
        path: '/customer/customer-view/:id',
        name: 'customer-view',
        component: () => import('@/views/sales/customers/Edit/Main.vue'),
        meta: {
            pageTitle: 'View Customer',
            breadcrumb: [
                {
                    text: 'Customers List',

                    to: '/customer/customer-list',
                },
                {
                    text: 'View Customer',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },



]