import * as types from "./types";
import { 
    getFileListAxios, 
    deleteFileAxios,
    postFileAxios,
    putFileAxios
} from "./services";

export async function getFileListAction({commit}) {
    commit(types.LOADING_FILE, true);
    try {
        const {data} = await getFileListAxios();
        commit(types.GET_FILE_LIST, data.result);
    }
    catch(e) {
       
        throw e;
    }
    finally{
        commit(types.LOADING_FILE, false);
    }
}

export async function removeFileAction({commit}, payload) {
    commit(types.LOADING_FILE, true);
    try {
        await deleteFileAxios(payload);
        commit(types.REMOVE_FILE, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally{
        commit(types.LOADING_FILE, false);
    }
}

export async function addFileAction({commit}, payload) {
    commit(types.LOADING_FILE, true);
    try {
        await postFileAxios(payload);
        commit(types.ADD_FILE, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally{
        commit(types.LOADING_FILE, false);
    }
}

export async function updateFileAction({commit}, payload) {
    commit(types.LOADING_FILE, true);
    try {
        await putFileAxios(payload);        
        commit(types.UPDATE_FILE, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally{
        commit(types.LOADING_FILE, false);
    }

}