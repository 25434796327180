export default [
    // *===================Job Card List============================---*

    {
        path: '/jobcard/list',
        name: 'jobcard-list',
        component: () => import('@/views/production/jobcard/jobcard-list/JobCardList.vue'),
        meta: {
            pageTitle: 'Job Cards List',
            breadcrumb: [
                {
                    text: 'Job Cards List',
                    active: true,
                },
            ],
            permission: 'Permissions.Production.View'            
        },
    },



    // *===================Job Card Add============================---*
    {
        path: '/jobcard/add',
        name: 'jobcard-add',
        component: () => import('@/views/production/jobcard/jobcard-add/JobcardAdd.vue'),
        meta: {
            pageTitle: 'Add Job Card',
            breadcrumb: [
                {
                    text: 'Job Cards List',
                    to: '/jobcard/list',
                },
                {
                    text: 'Add Job Card',
                    active: true,
                },
            ],
            permission: 'Permissions.Production.View'  
        },
    },





    // *===================Job Card View============================---*
    {
        path: '/jobcard/view/:id',
        name: 'jobcard-view',
        component: () => import('@/views/production/jobcard/jobcard-view/jobcardview.vue'),
        meta: {
            pageTitle: 'View Job Card',
            breadcrumb: [
                {
                    text: 'Job Cards List',
                    to: '/jobcard/list',
                },
                {
                    text: 'View Job Card',
                    active: true,
                },
            ],
        },
    },
]