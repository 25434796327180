export default[
    // *===============================================---*
    /// TEMPLATES ///
    {
        path: '/servicetemplates/list',
        name: 'templates-list',
        component: () => import('@/views/sales/services/template/TemplateList.vue'),
        meta: {
            pageTitle: 'Service Templates List',
            breadcrumb: [
                {
                    text: 'Service Templates List',
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
    {
        path: '/servicetemplates/add',
        name: 'templates-add',
        component: () => import('@/views/sales/services/template/TemplateAdd.vue'),
        meta: {
            pageTitle: 'Add Service Template',
            breadcrumb: [
                {
                    text: 'Service Templates List',
                    to: '/servicetemplates/list',
                },
                {
                    text: 'Add Service Template',
                    active: true
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },    
    {
        path: '/servicetemplates/edit/:id',
        name: 'templates-edit',
        component: () => import('@/views/sales/services/template/TemplateEdit.vue'),
        meta: {
            pageTitle: 'Edit Service Template',
            breadcrumb: [
                {
                    text: 'Service Template List',
                    to: '/servicetemplates/list',
                },
                {
                    text: 'Edit Service Template',
                    active: true
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },    

    /// SERVICES ///
    {
        path: '/servicerequest/list',
        name: 'request-list',
        component: () => import('@/views/sales/services/requests/RequestList.vue'),
        meta: {
            pageTitle: 'Service Requests List',
            breadcrumb: [
                {
                    text: 'Service Request Lists',
                    active:true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
      
    },
    {
        path: '/servicerequest/add',
        name: 'servicerequest-add',
        component: () => import('@/views/sales/services/requests/add/main.vue'),
        meta: {
            pageTitle: 'Add Service Request',
            breadcrumb: [
                {
                    text: 'Service Requests List',
                    to: '/servicerequest/list',
                },
                {
                    text: 'Add Service Request',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
       
    },
    {
        path: '/servicerequest/edit/:id',
        name: 'servicerequest-edit',
        component: () => import('@/views/sales/services/requests/edit/main.vue'),
        meta: {
            pageTitle: 'Edit Service Request',
            breadcrumb: [
                {
                    text: 'Service Requests List',
                    to: '/servicerequest/list',
                },
                {
                    text: 'Edit Service Request',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
    {
        path: '/servicerequest/view/:id',
        name: 'servicerequest-view',
        component: () => import('@/views/sales/services/requests/view/main.vue'),
        meta: {
            pageTitle: 'View Service Request',
            breadcrumb: [
                {
                    text: 'Service Requests List',
                    to: '/servicerequest/list',
                },
                {
                    text: 'View Service Request',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
     
    },
]