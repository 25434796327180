import api from "@/api/api-v2-config";

export async function getItemBalanceListAxios() {    
    return await api.get("itemBalances?pageNumber=1&pageSize=10000");
}

export async function getItemBalanceByIDAxios(id) {    
    return await api.get("itemBalances/" + id);
}

export async function deleteItemBalanceAxios(id) {    
    return await api.delete("itemBalances?id=" + id);
}

export async function postItemBalanceAxios(itemBalance) {    
    return await api.post("itemBalances", itemBalance);
}

export async function putItemBalanceAxios(itemBalance) {    
    return await api.put("itemBalances", itemBalance);
}