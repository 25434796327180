export default [
    // *===============================================---*
    // *--------- Error 404  ---------------------------------------*
    // *===============================================---*
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    // *===============================================---*
    // *--------- lOGIN  ---------------------------------------*
    // *===============================================---*
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    // *===============================================---*
    // *--------- Register - Currently won't be used  ---------------------------------------*
    // *===============================================---*
    {
        path: '/register',
        name: 'auth-register',
        component: () => import('@/views/authentication/Register.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    // *===============================================---*
    // *--------- FORGOT PASSWORD  ---------------------------------------*
    // *===============================================---*
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
           
        },
    },

    {
        path: '/otp-verification',
        name: 'otp-verification',
        component: () => import('@/views/authentication/Otp.vue'),
        meta: {
            layout: 'full',
        },
    },

    // *===============================================---*
    // *--------- COMING SOON  ---------------------------------------*
    // *===============================================---*
    {
        path: '/pages/miscellaneous/coming-soon',
        name: 'misc-coming-soon',
        component: () => import('@/views/miscellaneous/ComingSoon.vue'),
        meta: {
            layout: 'full',
        },
    },


        // *===============================================---*
    // *--------- COMING SOON  ---------------------------------------*
    // *===============================================---*
    {
        path: '/account-setting',
        name: 'pages-account-setting',
        component: () => import('@/views/settings/AccountSetting.vue'),
        meta: {
          pageTitle: 'Account Settings',
          activeTab: null,
          breadcrumb: [
            {
              text: 'Account Settings',
              active: true,
            },
          ],
        },
      },
//facebook only
      {
        path: '/account-setting',
        name: 'pages-facebook-setting',
        component: () => import('@/views/settings/AccountSetting.vue'),
        meta: {
          pageTitle: 'Account Settings',
          activeTab: 'facebook',
          breadcrumb: [
            {
              text: 'Account Settings',
              active: true,
            },
          ],
        },
      },
    // *===============================================---*
    // *--------- Not Authorized  ---------------------------------------*
    // *===============================================---*
    {
        path: '/pages/miscellaneous/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    // *===============================================---*
    // *--------- Under Maintainance  ---------------------------------------*
    // *===============================================---*
    {
        path: '/pages/miscellaneous/under-maintenance',
        name: 'misc-under-maintenance',
        component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
        meta: {
            layout: 'full',
        },
    },

    // *===============================================---*
    // *--------- Error  ---------------------------------------*
    // *===============================================---*
    {
        path: '/pages/miscellaneous/error',
        name: 'misc-error',
        component: () => import('@/views/miscellaneous/Error.vue'),
        meta: {
            layout: 'full',
        },
    },
]
