export default [
  {
    path: "/inventory-transactions/list",
    name: "inventory-transaction-list",
    component: () =>
      import("@/views/inventory/transactions/TransactionList.vue"),
    meta: {
      pageTitle: "Inventory Transaction List",
      breadcrumb: [
        {
          text: "Inventory Transaction List",
          active: true,
        },
      ],
      permission: 'Permissions.Inventory.View'  
    },
  },
  {
    path: "/inventory-transactions/view/:id",
    name: "inventory-transaction-view",
    component: () =>
      import("@/views/inventory/transactions/TransactionView.vue"),
    meta: {
      pageTitle: "View Inventory Transaction",
      breadcrumb: [
        {
          text: "Inventory Transaction List",
          to: "/inventory-transactions/list",
        },
        {
          text: "View Inventory Transaction",
          active: true,
        },
      ],
      permission: 'Permissions.Inventory.View'  
    },
  },
  {
    path: "/inventory-transactions/add",
    name: "inventory-transaction-add",
    component: () =>
      import("@/views/inventory/transactions/TransactionAdd.vue"),
    meta: {
      pageTitle: "Add Inventory Transaction",
      breadcrumb: [
        {
          text: "Inventory Transaction List",
          to: "/inventory-transactions/list",
        },
        {
          text: "Add Inventory Transaction",
          active: true,
        },
      ],
      permission: 'Permissions.Inventory.View'  
    },
  },  
  {
    path: "/inventory-transactions/edit/:id",
    name: "inventory-transaction-edit",
    component: () =>
      import("@/views/inventory/transactions/TransactionEdit.vue"),
    meta: {
      pageTitle: "Edit Inventory Transaction",
      breadcrumb: [
        {
          text: "Inventory Transaction List",
          to: "/inventory-transactions/list",
        },
        {
          text: "Edit Inventory Transaction",
          active: true,
        },
      ],
      permission: 'Permissions.Inventory.View'  
    },
  }
];
