import * as types from "./types";

const mutations = {
    [types.LOADING_USER](state, value) {
        state.loading = value;
    },
    [types.GET_USER_LIST](state, users) {
        state.users = users;
    },

    [types.GET_ROLE](state, role) {
        state.role = role;
    },
    [types.GET_USER_ID](state, usersId) {
        state.usersId = usersId;
    },
    [types.ADD_USER](state, users) {        
        state.users.unshift(users);
    },
    [types.ADD_USER_ROLE](state, role) {        
        const roleIndex = state.role.findIndex((x) => x.id === role.id);
        state.role[roleIndex] = role;        
    },


};

export default mutations;