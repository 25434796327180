import api from "@/api/api-v2-config";

export async function getQuotationsListAxios() {    
    return await api.get("quotes");
}

export async function deleteQuotationsAxios(id) {    
    return await api.delete("quotes?Id=" + id);
}

export async function postQuotationsAxios(payload) {    
    return await api.post("quotes", payload);
}

export async function putQuotationsAxios(payload) {    
    return await api.put("quotes", payload);
}

export async function getQuotationByIdAxios(id) {    
    return await api.get("quotes/" + id);
}