export default [
    // *===============================================---*
    ///SERVICES ///
    {
        path: '/complaint/list',
        name: 'complaint-list',
        component: () => import('@/views/sales/complaints/main.vue'),
        meta: {
            pageTitle: 'Complaint List',
            breadcrumb: [
                {
                    text: 'Complaint List',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },


    // correct code 
    // {
    //     path: '/complaint/view/:id',
    //     name: 'complaint-view',
    //     component: () => import('@/views/sales/complaints/Viewcomplaint.vue'),
    //     meta: {
    //         pageTitle: 'View complaint',
    //         breadcrumb: [
    //             {
    //                 text: 'complaint List',
    //                 to: '/complaint/list'
    //             },
    //             {
    //                 text: 'View complaint',
    //                 active: true,
    //             },
    //         ],
    //         permission: 'Permissions.Sales.View'
    //     },
    // },

{
    path: '/complaint/view',
    name: 'complaint-view',
    component: () => import('@/views/sales/complaints/ViewComplaint.vue'),
    meta: {
        pageTitle: 'View complaint',
        breadcrumb: [
            {
                text: 'Complaint List',
                to: '/complaint/list'
            },
            {
                text: 'View Complaint',
                active: true,
            },
        ],
        permission: 'Permissions.Sales.View'
    },
},


]