import * as types from "./types";

const mutations = {
    [types.LOADING_RAWMATERIAL](state, value) {
        state.loading = value;
    },
    [types.GET_RAWMATERIAL_LIST](state, rawmaterials) {
        state.rawmaterials = rawmaterials;
    },
    [types.GET_RAWMATERIAL_ID](state, rawmaterialsID) {
        state.rawmaterialsID = rawmaterialsID;
    },
    [types.REMOVE_RAWMATERIAL](state, id) {        
        state.rawmaterials = state.rawmaterials.filter((x) => x.id !== id);        
    },
    [types.ADD_RAWMATERIAL](state, rawmaterials) {        
        state.rawmaterials.unshift(rawmaterials);
    },
    [types.UPDATE_RAWMATERIAL](state, rawmaterials) {        
        const RawMaterialIndex = state.rawmaterials.findIndex((x) => x.id === rawmaterials.id);
        state.rawmaterials[RawMaterialIndex] = rawmaterials;        
    }
};

export default mutations;