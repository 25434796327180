import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  invoices: [
    {
      id: 4987,
      issuedDate: '13 Dec 2019',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        DueDate: '25 Sep 2022',
        name: 'Product 1',
      },
      service: 'Software Development',
      total: 3428,
      avatar: '',
      invoiceStatus: 'Paid',
      FinishDate: '01 Dec 2022',
      qty: 99,
      dueDate: '23 Apr 2019',
    },
    {
      id: 4988,
      issuedDate: '17 Jul 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'Mccann LLC and Sons',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        DueDate: '25 Sep 2022',
        name: 'Product 6',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      invoiceStatus: 'Downloaded',
      qty: 58,
      dueDate: '15 Dec 2019',
      FinishDate: '01 Dec 2022',
    },
    {
      id: 4989,
      issuedDate: '19 Oct 2019',
      client: {
        address: '5345 Robert Squares',
        company: 'Leonard-Garcia and Sons',
        companyEmail: 'smithtiffany@powers.com',
        country: 'Denmark',
        contact: '(955) 676-1076',
        DueDate: '25 Sep 2022',
        name: 'Product 2',
      },
      service: 'Unlimited Extended License',
      total: 3719,
      invoiceStatus: 'Paid',
      qty: 58,
      FinishDate: '01 Dec 2022',
      dueDate: '03 Nov 2019',
    },
   
    {
      id: 5025,
      issuedDate: '21 Feb 2020',
      client: {
        address: '633 Bell Well Apt. 057',
        company: 'Adams, Simmons and Brown Group',
        companyEmail: 'kayla09@thomas.com',
        country: 'Martinique',
        contact: '(266) 611-9482',
        DueDate: '25 Sep 2022',
        name: 'Product 3',
      },
      service: 'UI/UX Design & Development',
      total: 5591,
      avatar: '',
      invoiceStatus: 'Downloaded',
      qty: 959,
      FinishDate: '01 Dec 2022',
      dueDate: '07 Jun 2019',
    },
    {
      id: 5026,
      issuedDate: '24 May 2019',
      client: {
        address: '1068 Lopez Fall',
        company: 'Williams-Lawrence and Sons',
        companyEmail: 'melvindavis@allen.info',
        country: 'Mexico',
        contact: '(739) 745-9728',
        name: 'Product 4',
        DueDate: '25 Sep 2022',
      },
      service: 'Template Customization',
      total: 2783,
      invoiceStatus: 'Draft',
      FinishDate: '01 Dec 2022',
      qty: 52,
      dueDate: '22 Oct 2019',
    },
    {
      id: 5036,
      issuedDate: '19 Apr 2019',
      client: {
        address: '78083 Laura Pines',
        company: 'Company 1',
        companyEmail: 'pwillis@cross.org',
        country: 'Bhutan',
        contact: '(687) 660-2473',
        DueDate: '25 Sep 2022',
        name: 'Product 5',
      },
      service: 'Unlimited Extended License',
      total: 3171,
      invoiceStatus: 'Paid',
      qty: 854,
      dueDate: '25 Sep 2019',
      FinishDate: '01 Dec 2022',
    },
    {
        id: 5036,
        issuedDate: '19 Apr 2019',
        client: {
          address: '78083 Laura Pines',
          company: 'Company 1',
          companyEmail: 'pwillis@cross.org',
          country: 'Bhutan',
          contact: '(687) 660-2473',
          DueDate: '25 Sep 2022',
          name: 'Product 1',
        },
        service: 'Unlimited Extended License',
        total: 3171,
        invoiceStatus: 'Paid',
        qty: 848,
        dueDate: '25 Sep 2019',
        FinishDate: '01 Dec 2022',
      },
      {
        id: 5036,
        issuedDate: '19 Apr 2019',
        client: {
          address: '78083 Laura Pines',
          company: 'Company 1',
          companyEmail: 'pwillis@cross.org',
          country: 'Bhutan',
          contact: '(687) 660-2473',
          DueDate: '25 Sep 2019',
          name: 'Product 2',
        },
        service: 'Unlimited Extended License',
        total: 3171,
        invoiceStatus: 'Paid',
        qty: 58,
        FinishDate: '01 Dec 2022',
        dueDate: '25 Sep 2019',
        status: 0,
      },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/jobcard/jobcards').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.invoices.filter(
    invoice =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (invoice.client.companyEmail.toLowerCase().includes(queryLowered) ||
        invoice.client.name.toLowerCase().includes(queryLowered)) &&
      invoice.invoiceStatus === (status || invoice.invoiceStatus),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      invoices: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/apps\/jobcard\/jobcards\/\d+/).reply(config => {
  // Get event id from URL
  let invoiceId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const invoice = data.invoices[invoiceIndex]
  const responseData = {
    invoice,
    paymentDetails: {
      totalDue: 'Rs 12,110.55',
      bankName: 'MCB',
      country: 'Mauritius',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (invoice) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/invoice/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
