export default [
    {
        path: '/proforma/list',
        name: 'proforma-list',
        component: () => import('@/views/sales/salesorder/list.vue'),
        meta: {
            pageTitle: 'Proforma List',
            breadcrumb: [
                {
                    text: 'Proforma List',
                    active: true,
                },

            ],
            permission: 'Permissions.Sales.View'
        },
    },
    {
        path: '/proforma/view/:id',
        name: 'proforma-view',
        component: () => import('@/views/sales/salesorder/View.vue'),
        meta: {
            pageTitle: 'View Proforma',
            breadcrumb: [
                {
                    text: 'Proforma List',
                    to: '/proforma/list'
                },
                {
                    text: 'View Proforma',
                    active: true,
                },

            ],
            permission: 'Permissions.Sales.View'
        },
    },

    {
        path: '/proforma/add',
        name: 'proforma-add',
        component: () => import('@/views/sales/salesorder/AddSoNew.vue'),
        meta: {
            pageTitle: 'Add Proforma',
            breadcrumb: [
                {
                    text: 'Proforma List',
                    to: '/proforma/list'
                },
                {
                    text: 'Add Proforma',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
 
    {
        path: '/proforma/edit/:id',
        name: 'proforma-edit',
        component: () => import('@/views/sales/salesorder/PreviewEdit.vue'),
        meta: {
            pageTitle: 'Edit Proforma',
            breadcrumb: [
                {
                    text: 'Proforma List',
                    to: '/proforma/list'
                },
                {
                    text: 'Edit Proforma',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
]