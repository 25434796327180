import * as types from "./types";

const mutations = {
    [types.LOADING_MODULE](state, value) {
        state.loading = value;
    },
    [types.GET_MODULE](state, module) {
        state.module = module;
    },
};

export default mutations;