import * as types from "./types";

import {
    deletePayment,
    postPayment,
    getPayment
} from "./services";



export async function removePaymentAction({commit}, payload) {
    commit(types.LOADING_PAYMENT, true);
    try {
        await deletePayment(payload);
        commit(types.REMOVE_PAYMENT, payload);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_PAYMENT, false);
}

export async function addPaymentAction({commit}, payload) {
    commit(types.LOADING_PAYMENT, true);
    try {
        await postPayment(payload);        
        commit(types.ADD_PAYMENT, payload);
    }
    catch(e) {
        throw e;
    }
    finally{
        commit(types.LOADING_PAYMENT, false);
    }
  
}


export async function getPaymentByIDAction({commit}, payload) {
    commit(types.LOADING_PAYMENT, true);
    try {

        const {data} = await getPayment(payload);
        commit(types.GET_PAYMENT, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_PAYMENT, false);
}
