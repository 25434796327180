import * as types from "./types";

const mutations = {
    [types.LOADING_PRODUCT](state, value) {
        state.loading = value;
    },
    [types.GET_PRODUCT_LIST](state, product) {
        state.product = product;
    },
    [types.GET_PRODUCT_ID](state, productID) {
        state.productID = productID;
    },
    [types.REMOVE_PRODUCT](state, id) {        
        state.product = state.product.filter((x) => x.id !== id);        
    },
    [types.ADD_PRODUCT](state, product) {        
        state.product.unshift(product);
    },
    [types.UPDATE_PRODUCT](state, product) {        
        const ProductIndex = state.product.findIndex((x) => x.id === product.id);
        state.product[ProductIndex] = product;        
    }
};

export default mutations;