import api from "@/api/api-v1-config";

export async function getTenantListAxios() {
    return await api.get("tenants");
}


export async function postTenantAxios(tenant) {
    return await api.post("tenants", tenant);
}

export async function putTenantAxios(tenant) {
    return await api.put("tenants", tenant);
}


export async function getTenantByIdAxios(id) {
    return await api.get("tenants/" + id);
}

export async function activateTenantAxios(id) {
    return await api.post("tenants/" + id + "/activate");
}


export async function deactivateTenantAxios(id) {
    return await api.post("tenants/" + id + "/deactivate");
}


