import api from "@/api/api-v2-config";

export async function getCustomersListAxios() {    
    return await api.get("customers?pageNumber=1&pageSize=10000");
}

export async function deleteCustomersAxios(id) {    
    return await api.delete("customers/" + id);
}

export async function postCustomersAxios(customers) {    
    return await api.post("customers", customers);
}

export async function putCustomersAxios(customers) {    
    return await api.put("customers", customers);
}

export async function getCustomersByIDAxios(id) {    
    return await api.get("customers/" + id);
}

export async function getOrdersByIDAxios(id) {    
    return await api.get("customers/" + id + "/orders");
}

export async function getQuotationsByIDAxios(id) {    
    return await api.get("customers/" + id + "/quotes");
}

export async function getServiceRequestsByIDAxios(id) {    
    return await api.get("customers/" + id + "/ServiceRequests");
}

export async function getInvoicesByIDAxios(id) {    
    return await api.get("customers/" + id + "/invoices");
}