import * as types from "./types";
import {
    getLeadListAxios,
    deleteLeadAxios,
    postLeadAxios,
    putLeadAxios,
    getLeadByIDAxios,
    getLeadQuotationsAxios,
    getLeadContactPersonAxios,
    getLeadConvertAxios
} from "./services";

export async function getLeadListAction({ commit }) {
    commit(types.LOADING_LEAD, true);
    try {
        const { data } = await getLeadListAxios();
        commit(types.GET_LEAD_LIST, data.result);
    }
    catch (e) {
        
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
}

export async function removeLeadAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        await deleteLeadAxios(payload);
        commit(types.REMOVE_LEAD, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
}

export async function addLeadAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        const { data } = await postLeadAxios(payload);
        commit(types.ADD_LEAD, payload);
        return data;
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
}

export async function updateLeadAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        await putLeadAxios(payload);
        commit(types.UPDATE_LEAD, payload);
    }
    catch (e) {
        
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
   
}

export async function getLeadByIDAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        const {data} =await getLeadByIDAxios(payload);
        commit(types.GET_LEADBYID, data.result);
    }
    catch (e) {
        
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }

}

export async function getLeadQuotationsAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        const { data } = await getLeadQuotationsAxios(payload);
        commit(types.GET_LEAD_QUOTATIONS, data.result);
    }
    catch (e) {
        
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
}

export async function getLeadContactPersonAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        // await getLeadContactPersonAxios(payload);
        // commit(types.GET_LEAD_CONTACT_PERSON, payload);
        const { data } = await getLeadContactPersonAxios(payload);
        commit(types.GET_LEAD_CONTACT_PERSON, data.result.data);
    }
    catch (e) {
        
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
}

export async function getLeadConvertAction({ commit }, payload) {
    commit(types.LOADING_LEAD, true);
    try {
        await getLeadConvertAxios(payload);
        commit(types.GET_LEAD_CONVERT, payload);
    }
    catch (e) {
        
        throw e;
    }
    finally {
        commit(types.LOADING_LEAD, false);
    }
}

