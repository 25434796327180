import * as types from "./types";

const mutations = {
    [types.LOADING_ROLE](state, value) {
        state.loading = value;
    },
    [types.GET_ROLE_LIST](state, role) {
        state.role = role;
    },
    [types.REMOVE_ROLE](state, id) {        
        state.role = state.role.filter((x) => x.id !== id);        
    },
    [types.ADD_ROLE](state, role) {        
        state.role.unshift(role);
    },
    [types.UPDATE_ROLE](state, role) {        
        const RoleIndex = state.role.findIndex((x) => x.id === role.id);
        state.role[RoleIndex] = role;        
    }
};

export default mutations;