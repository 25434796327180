import * as types from "./types";

const mutations = {
    [types.LOADING_LABOUR](state, value) {
        state.loading = value;
    },
    [types.GET_LABOUR_LIST](state, labour) {
        state.labour = labour;
    },
    [types.REMOVE_LABOUR](state, id) {        
        state.labour = state.labour.filter((x) => x.id !== id);        
    },
    [types.ADD_LABOUR](state, labour) {        
        state.labour.unshift(labour);
    },
    [types.UPDATE_LABOUR](state, labour) {        
        const LabourIndex = state.labour.findIndex((x) => x.id === labour.id);
        state.labour[LabourIndex] = labour;        
    }
};

export default mutations;