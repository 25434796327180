import lead from ".";
import * as types from "./types";

const mutations = {
    [types.LOADING_LEAD](state, value) {
        state.loading = value;
    },
    [types.GET_LEAD_LIST](state, leads) {
        state.leads = leads;
    },
    [types.REMOVE_LEAD](state, id) {        
        state.leads = state.leads.filter((x) => x.id !== id);        
    },
    [types.ADD_LEAD](state, lead) {        
        state.leads.unshift(lead);
    },
    [types.UPDATE_LEAD](state, lead) {        
        const leadIndex = state.leads.findIndex((x) => x.id === lead.id);
        state.leads[leadIndex] = lead;        
    },
    [types.GET_LEADBYID](state, leadId) {        
        state.leadId = leadId;       
    },
    [types.GET_LEAD_QUOTATIONS](state, quotations){
        state.quotations = quotations;
       // state.leads = state.lead.filter((x) => x.id === lead.id && x.quotations === data.quotations)
    },
    // [types.GET_LEAD_CONTACT_PERSON](state, id){
    //     state.contactperson = state.contactperson.filter((x) => x.id === id && x.contactperson === data.contactperson)
    // },

    [types.GET_LEAD_CONTACT_PERSON](state, contacts) {
        state.contacts = contacts;
    },


    [types.GET_LEAD_CONVERT](state, id){
        state.leads = state.leads.filter((x) => x.id === lead.id && x.convert === data.convert)
    },

};

export default mutations;