import { data } from "jquery";
import * as types from "./types";

const mutations = {
    [types.LOADING_CONTACTS](state, value) {
        state.loading = value;
    },
    [types.GET_CONTACTS_LIST](state, contacts) {
        state.contacts = contacts;
    },
    [types.REMOVE_CONTACT](state, id) {        
        state.contacts = state.contacts.filter((x) => x.id !== id);        
    },
    [types.ADD_CONTACT](state, contact) {        
        state.contacts.unshift(contact);
    },
    [types.UPDATE_CONTACT](state, contacts) {        
        const contactsIndex = state.contacts.findIndex((x) => x.id === contacts.id);
        state.contacts[contactsIndex] = contacts;        
    },
    [types.PRIMARY_CONTACT](state, contacts) {        
        const contactsIndex = state.contacts.findIndex((x) => x.id === contacts.id);
        state.contacts[contactsIndex] = contacts;        
    },
    [types.DELETE_CONTACT_LEAD](state, id) {        
        state.contacts = state.contacts.filter((x) => x.id !== id);        
    },
};

export default mutations;