import api from "@/api/api-v2-config";

export async function getWarehouseAxios() {    
    return await api.get("warehouses?pageNumber=1&pageSize=1000");
}

export async function deleteWarehouseAxios(id) {    
    return await api.delete("warehouses?Id=" + id);
}

export async function postWarehouseAxios(item) {    
    return await api.post("warehouses", item);
}

export async function putWarehouseAxios(item) {    
    return await api.put("warehouses", item);
}