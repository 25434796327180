import Vue from 'vue';
import Vuex from 'vuex';

// Modules

import app from './app';
import appConfig from './app-config';
import authModule from "./auth";
import verticalMenu from './vertical-menu';
import leadModule from './lead';
import equipmentModule from './equipment';
import customerModule from './customers';
import invoiceModule from './invoices';
import labourModule from './labour';
import supplierModule from './suppliers';
import salesOrderModule from './salesorders';
import purchaseOrderModule from './purchaseorder';
import rawmaterialModule from './RawMaterial';
import salesPersonModule from './SalesPerson';
import itemcategoryModule from './ItemCategory';
import itemUomModule from './ItemUOM';
import productModule from './product';
import contactModule from './contact';
import jobcardModule from './jobcard';
import quotationModule from './quotation'
import userModule from './users';
import pushModule from './pushorder';
import roleModule from './roles';
import tenantModule from  './tenants';
import reportModule from './reports';
import ticketModule from './tickets';
import inventoryTransactionModule from './transaction';
import warehouseModule from './warehouse';
import personalModule from './personal';
import notificationModule from './notification';
import serviceModule from './servicerequests';
import paymentModule from './payments';
import itemBalanceModule from './itemBalance'
import deliveryModule from './deliverynotes'
import marketingModule from './marketing'
import moduleModule from './module'
import fileModule from './file'
import noteModule from './note'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authModule,
    leadModule,
    equipmentModule,
    customerModule,
    invoiceModule,
    labourModule,
    supplierModule,
    salesOrderModule,
    purchaseOrderModule,
    rawmaterialModule,
    itemcategoryModule,
    itemUomModule,
    productModule,
    salesPersonModule,
    contactModule,
    jobcardModule,
    quotationModule,
    userModule,
    pushModule,
    roleModule,
    tenantModule,
    reportModule,
    ticketModule,
    inventoryTransactionModule,
    warehouseModule,
    personalModule,
    notificationModule,
    serviceModule,
    paymentModule,
    itemBalanceModule,
    deliveryModule,
    marketingModule,
    moduleModule,
    fileModule,
    noteModule

  },
  strict: process.env.DEV,
})
