import { data } from "jquery";
import * as types from "./types";

const mutations = {
    [types.LOADING_CUSTOMERS](state, value) {
        state.loading = value;
    },
    [types.GET_CUSTOMERS_LIST](state, customers) {
        state.customers = customers;
    },
    [types.REMOVE_CUSTOMERS](state, id) {        
        state.customers = state.customers.filter((x) => x.id !== id);        
    },
    [types.ADD_CUSTOMERS](state, customers) {        
        state.customers.unshift(customers);
    },
    [types.UPDATE_CUSTOMERS](state, customers) {        
        const customersIndex = state.customers.findIndex((x) => x.id === customers.id);
        state.customers[customersIndex] = customers;        
    },
    [types.GET_CUSTOMERSBYID](state, custId) {         
        state.custId = custId;    
    },

    [types.GET_ORDERSBYID](state, order) {        
        state.order = order;
    },
    [types.GET_QUOTATIONSBYID](state, quotation){
        state.quotation = quotation;
    },
    [types.GET_SERVICEREQUESTSBYID](state, servicerequests){
        state.servicerequests = state.servicerequests.filter((x) => x.id === id && x.servicerequests === servicerequests)
    },
    [types.GET_INVOICESBYID](state, invoice){
        state.invoice = invoice;
    },
    [types.LOADING_ORDERS](state, value) {
        state.loading = value;
    },
    [types.LOADING_QUOTATIONS](state, value) {
        state.loading = value;
    },
    [types.LOADING_SERVICEREQUESTS](state, value) {
        state.loading = value;
    },
    [types.LOADING_INVOICES](state, value) {
        state.loading = value;
    },
};

export default mutations;