import api from "@/api/api-v1-config";

export async function getAppIdAxios() {    
    return await api.get("marketing/getcred");
}

export async function postAppIdAxios(payload) {    
    return await api.post("marketing/createcred", payload);
}

export async function pagePosts(token) {    
    return await api.get("marketing/facebook/getpagepost?token=" + token);
}

export async function deletePosts(token, id) {    
    return await api.delete("marketing/facebook/deletepost?token=" + token + "&postid=" + id);
}



