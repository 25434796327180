export default [
    {
        path: '/support/dashboard',
        name: 'support-dashboard',
        component: () => import('@/views/support/DashboardSupport.vue'),
        meta: {
            pageTitle: 'Support Dashboard',
            breadcrumb: [
                {
                    text: 'Support Dashboard',
                    active: true,
                },
                
            ],
            permission: 'Permissions.Support.View'
        },
    },

    {
        path: '/support/submit',
        name: 'support-submit',
        component: () => import('@/views/support/TicketForm.vue'),
        meta: {
            layout: 'full',
            pageTitle: 'Layout Blank',
          },
          
    },
]
