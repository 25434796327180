import * as types from "./types";

const mutations = {
    [types.LOADING_ITEM_BALANCE](state, value) {
        state.loading = value;
    },
    [types.GET_ITEM_BALANCE_LIST](state, itemBalances) {
        state.itemBalances = itemBalances;
    },    
    [types.REMOVE_ITEM_BALANCE](state, id) {        
        state.itemBalances = state.itemBalances.filter((x) => x.id !== id);        
    },
    [types.ADD_ITEM_BALANCE](state, itemBalance) {        
        state.itemBalances.unshift(itemBalance);
    },
    [types.UPDATE_ITEM_BALANCE](state, itemBalance) {        
        const index = state.itemBalances.findIndex((x) => x.id === itemBalance.id);
        state.itemBalances[index] = itemBalance;        
    }    
};

export default mutations;