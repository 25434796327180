import api from "@/api/api-v2-config";

export async function getLabourAxios() {    
    return await api.get("labours/labours?pageNumber=1&pageSize=100000");
}

export async function deleteLabourAxios(id) {    
    return await api.delete("labours/labours?Id=" + id);
}

export async function postLabourAxios(item) {    
    return await api.post("labours/labours", item);
}

export async function putLabourAxios(item) {    
    return await api.put("labours/labours", item);
}