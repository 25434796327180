import axios from 'axios';
import useJwt from '@/auth/jwt/useJwt';

const baseUrl = process.env.VUE_APP_API_URLv1;

function generateNonce(length = 16) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let nonce = '';
    for (let i = 0; i < length; i++) {
        nonce += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return nonce;
}

let api = axios.create({
    baseURL: baseUrl,
});

api.interceptors.request.use(config => {
    const token = useJwt.getToken();
    const tenant = localStorage.getItem('userTenant');
    const nonce = generateNonce();

    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['tenant'] = tenant;
    config.headers['Nonce'] = nonce;
    config.headers['Content-Type'] = 'application/json';

    return config;
}, error => {
    return Promise.reject(error);
});

export default api;
