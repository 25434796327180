import * as types from "./types";

const mutations = {
    [types.LOADING_INVOICES](state, value) {
        state.loading = value;
    },
    [types.GET_INVOICES_LIST](state, invoices) {
        state.invoices = invoices;
    },
    [types.GET_INVOICES_ID](state, invoiceId) {
        state.invoiceId = invoiceId;
    },
    [types.REMOVE_INVOICES](state, id) {        
        state.invoices = state.invoices.filter((x) => x.id !== id);        
    },
    [types.ADD_INVOICES](state, invoices) {        
        state.invoices.unshift(invoices);
    },
    [types.UPDATE_INVOICES](state, invoices) {        
        const invoicesIndex = state.invoices.findIndex((x) => x.id === invoices.id);
        state.invoices[invoicesIndex] = invoices;        
    }
};

export default mutations;