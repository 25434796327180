export default [
    // *===============================================---*
    ///SERVICES ///
    {
        path: '/quotation/list',
        name: 'quotation-list',
        component: () => import('@/views/sales/quotation/list.vue'),
        meta: {
            pageTitle: 'Quotations List',
            breadcrumb: [
                {
                    text: 'Quotations List',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
    {
        path: '/quotation/preview/:id',
        name: 'quotation-preview',
        component: () => import('@/views/sales/quotation/preview.vue'),
        meta: {
          pageTitle: 'View Quotation',
          breadcrumb: [
            {
              text: 'Quotations List',
              to : '/quotation/list'
            },
            {
              text: 'View Quotation',
              active: true,
            },
          ],
          permission: 'Permissions.Sales.View'
          
        },
      },
      {
        path: '/quotation/edit/:id',
        name: 'quotation-edit',
        component: () => import('@/views/sales/quotation/EditQuote.vue'),
        meta: {
          pageTitle: 'Edit Quotation',
          breadcrumb: [
            {
              text: 'Quotations List',
              to : '/quotation/list'
            },
            {
              text: 'Edit Quotation',
              active: true,
            },
          ],
          permission: 'Permissions.Sales.View'
          
        },
      },
      {
        path: '/quotation/add',
        name: 'quotation-add',
        component: () => import('@/views/sales/quotation/AddQuote.vue'),
        meta: {
          pageTitle: 'Add Quotation',
          breadcrumb: [
            {
              text: 'Quotations List',
              to : '/quotation/list'
            },
            {
              text: 'Add Quotation',
              active: true,
            },
          ],
          permission: 'Permissions.Sales.View'
          
        },
      },
      {
        path: '/quotation/add/:id',
        name: 'quotation-add-lead',
        component: () => import('@/views/sales/quotation/AddLeadQuote.vue'),
        meta: {
          pageTitle: 'Add Quotation For New Lead',
          breadcrumb: [
            {
              text: 'Leads List',
              to : '/leads/leads-list'
            },
            {
              text: 'Add Quotation For New Lead',
              active: true,
            },
          ],
          permission: 'Permissions.Sales.View'
          
        },
      },
      {
        path: '/quotation/add/customer/:id',
        name: 'quotation-add-customer',
        component: () => import('@/views/sales/quotation/AddCustomerQuote.vue'),
        meta: {
          pageTitle: 'Add Quotation For Customer',
          breadcrumb: [
            {
              text: 'Customers List',
              to : '/leads/leads-list'
            },
            {
              text: 'Add Quotation For Customer',
              active: true,
            },
          ],
          permission: 'Permissions.Sales.View'
          
        },
      },
]