import * as types from "./types";
import {
  getEquipmentListAxios,
  deleteEquipmentAxios,
  postEquipmentAxios,
  putEquipmentAxios,
} from "./services";

export async function getEquipmentListAction({ commit }) {
  commit(types.LOADING_EQUIPMENT, true);
  try {
    const { data } = await getEquipmentListAxios();
    commit(types.GET_EQUIPMENT_LIST, data.result.data);
  } catch (e) {

    throw e;
  } finally {
    commit(types.LOADING_EQUIPMENT, false);
  }
}

export async function removeEquipmentAction({ commit }, payload) {
  commit(types.LOADING_EQUIPMENT, true);
  try {
    await deleteEquipmentAxios(payload);
    commit(types.REMOVE_EQUIPMENT, payload);
  } catch (e) {

    throw e;
  } finally {
    commit(types.LOADING_EQUIPMENT, false);
  }
}

export async function addEquipmentAction({ commit }, payload) {
  commit(types.LOADING_EQUIPMENT, true);
  try {
    const { data } = await postEquipmentAxios(payload);
    payload.id = data.result.id;
    commit(types.ADD_EQUIPMENT, payload);
  } catch (e) {
 
    throw e;
  } finally {
    commit(types.LOADING_EQUIPMENT, false);
  }
}

export async function updateEquipmentAction({ commit }, payload) {
  commit(types.LOADING_EQUIPMENT, true);
  try {
    await putEquipmentAxios(payload);
    commit(types.UPDATE_EQUIPMENT, payload);
  } catch (e) {

    throw e;
  } finally {
    commit(types.LOADING_EQUIPMENT, false);
  }
}
