import api from "@/api/api-v2-config";

export async function getTicketListAxios() {    
    return await api.get("tickets/tickets?pageNumber=1&pageSize=100000");
}

export async function getTicketIdAxios(id) {    
    return await api.get("tickets/tickets/"+ id);
}

export async function deleteTicketAxios(ticket) {    
    return await api.delete("tickets/tickets", ticket);
}

export async function postTicketAxios(ticket) {    
    return await api.post("tickets/tickets", ticket);
}

export async function putTicketAxios(ticket) {    
    return await api.put("tickets/tickets", ticket);
}