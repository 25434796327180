import axios from 'axios'

const baseUrl = process.env.VUE_APP_API_URL

console.log('baseUrl: ', baseUrl);
console.log(process.env)

let tenant = localStorage.getItem('userTenant');
let headers = {
    'Content-Type': 'application/json',
    'tenant': tenant    
}

axios.defaults.headers.common['Authorization'] = 'Bearer null';
let api = axios.create({ 
    baseURL: baseUrl,
    headers: headers
}); 

export default api;
