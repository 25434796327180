import purchaseorders from ".";
import * as types from "./types";

const mutations = { 
    [types.LOADING_PURCHASEORDERS](state, value) {
        state.loading = value;
    },
    [types.GET_PURCHASEORDERS_LIST](state, purchaseorders) {
        state.purchaseorders = purchaseorders;
    },
    [types.REMOVE_PURCHASEORDERS](state, id) {        
        state.purchaseorders = state.purchaseorders.filter((x) => x.id !== id);        
    },
    [types.ADD_PURCHASEORDERS](state, purchaseorders) {        
        state.purchaseorders.unshift(purchaseorders);
    },
    [types.UPDATE_PURCHASEORDERS](state, purchaseorders) {        
        const purchaseordersIndex = state.purchaseorders.findIndex((x) => x.id === purchaseorders.id);
        state.purchaseorders[purchaseordersIndex] = purchaseorders;        
    },
    [types.GET_PURCHASEORDERSBYID](state, purchaseorderId) {
        state.purchaseorderId = purchaseorderId;
    }
};

export default mutations;