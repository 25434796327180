export default [
    {
        path: '/purchaseorders/list',
        name: 'purchaseorders-list',
        component: () => import('@/views/purchaseorder/list.vue'),
        meta: {
            pageTitle: 'Purchase Orders List',
            breadcrumb: [
                {
                    text: 'Purchase Orders List',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  

        },
    },
    {
        path: '/purchaseorders/add/:id/:quantity',
        name: 'purchaseorders-add',
        props: true,
        component: () => import('@/views/purchaseorder/Add.vue'),
        meta: {
            pageTitle: 'Create Purchase Order',
            breadcrumb: [
                {
                    text: 'Purchase Orders List',
                    to: '/purchaseorders/list',
                },
                {
                    text: 'Create Purchase Order',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  

        },
    },

    {
        path: '/purchaseorder/add',
        name: 'purchaseorder-add',
        component: () => import('@/views/purchaseorder/AddMain.vue'),
        meta: {
            pageTitle: 'Create Purchase Order',
            breadcrumb: [
                {
                    text: 'Purchase Orders List',
                    to: '/purchaseorders/list',
                },
                {
                    text: 'Create Purchase Order',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  

        },
    },




    {
        path: '/purchaseorder/view/:id',
        name: 'purchaseorder-view',
        component: () => import('@/views/purchaseorder/View.vue'),
        meta: {
            pageTitle: 'View Purchase Order',
            breadcrumb: [
                {
                    text: 'Purchase Orders List',
                    to: '/purchaseorders/list',
                },
                {
                    text: 'View Purchase Order',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  

        },
    },



]