const getters = {
    customers: (state) => state.customers,
    custId: (state) => state.custId,
    order: (state) => state.order,
    quotation: (state) => state.quotation,
    invoice: (state) => state.invoice,
    contacts: (state) => state.contacts,
    loading: state => state.loading
};

export default getters;