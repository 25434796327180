import * as types from "./types";

const mutations = {
    [types.LOADING_JOBCARD](state, value) {
        state.loading = value;
    },
    [types.GET_JOBCARD_LIST](state, jobcards) {
        state.jobcards = jobcards;
    },
    [types.GET_JOBCARD_DEMAND](state, jobDemand) {
        state.jobDemand = jobDemand;
    },
    [types.GET_JOBCARD_ID](state, jobId) {
        state.jobId = jobId;
    },
    [types.REMOVE_JOBCARD](state, id) {        
        state.jobcards = state.jobcards.filter((x) => x.id !== id);        
    },
    [types.ADD_JOBCARD](state, jobcards) {        
        state.jobcards.unshift(jobcards);
    },
    [types.UPDATE_JOBCARD](state, jobcards) {        
        const jobcardsIndex = state.jobcards.findIndex((x) => x.id === jobcards.id);
        state.jobcards[jobcardsIndex] = jobcards;        
    },
    [types.UPDATE_JOBCARD_STATUS](state, jobcards) {        
        const jobcardsIndex = state.jobcards.findIndex((x) => x.id === jobcards.id);
        state.jobcards[jobcardsIndex] = jobcards;        
    },
    [types.UPDATE_MULTIPLE_JOBCARD](state, jobcards) {
        jobcards.forEach((updateItem) => {
            const index = state.jobcards.findIndex((jobcard) => jobcard.id === updateItem.id);
            if (index !== -1) {
                state.jobcards[index].status = updateItem.status;
            }
        });
    }
};

export default mutations;