import * as types from "./types";

const mutations = {
    [types.LOADING_TICKET](state, value) {
        state.loading = value;
    },
    [types.GET_TICKET_LIST](state, ticket) {
        state.ticket = ticket;
    },
    [types.GET_TICKET_ID](state, ticketId) {
        state.ticketId = ticketId;
    },
    [types.REMOVE_TICKET](state, id) {        
        state.ticket = state.ticket.filter((x) => x.id !== id);        
    },
    [types.ADD_TICKET](state, ticket) {        
        state.ticket.unshift(ticket);
    },
    [types.UPDATE_TICKET](state, ticket) {        
        const ticketIndex = state.ticket.findIndex((x) => x.id === ticket.id);
        state.ticket[ticketIndex] = ticket;        
    }
};

export default mutations;