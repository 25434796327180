import * as types from "./types";
import { 
    getTenantListAxios, 
    activateTenantAxios,
    postTenantAxios,
    putTenantAxios,
    deactivateTenantAxios,
    getTenantByIdAxios
} from "./services";

export async function getTenantListAction({commit}) {
    commit(types.LOADING_TENANT, true);
    try {
        const {data} = await getTenantListAxios();
        commit(types.GET_TENANT_LIST, data.result);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally
    {
        commit(types.LOADING_TENANT, false);
    }
}

export async function getTenantIdAction({commit}, payload) {
    commit(types.LOADING_TENANT, true);
    try {
        const {data} = await getTenantByIdAxios(payload);
        commit(types.GET_TENANT_ID, data.result);
    }
    catch(e) {
        throw e;
    }
    finally
    {
        commit(types.LOADING_TENANT, false);
    }
}


export async function activateTenantAction({commit},payload) {
    commit(types.LOADING_TENANT, true);
    try {
        await activateTenantAxios(payload);
       
    }
    catch(e) {
        throw e;
    }
    finally
    {
        commit(types.LOADING_TENANT, false);
    }
}

export async function deactivateTenantAction({commit},payload) {
    commit(types.LOADING_TENANT, true);
    try {
        await deactivateTenantAxios(payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally
    {
        commit(types.LOADING_TENANT, false);
    }
}




export async function addTenantAction({commit}, payload) {
    commit(types.LOADING_TENANT, true);
    try {
        await postTenantAxios(payload);
        commit(types.ADD_TENANT, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally
    {
        commit(types.LOADING_TENANT, false);
    }
}

export async function updateTenantAction({commit}, payload) {
    commit(types.LOADING_TENANT, true);
    try {
        await putTenantAxios(payload);        
        commit(types.UPDATE_TENANT, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally
    {
        commit(types.LOADING_TENANT, false);
    }
}