import * as types from "./types";

import { 
    getDeliveryNotesListAxios,
    deleteDeliveryNotesAxios,
    postDeliveryNotesAxios,
    putDeliveryNotesAxios,
    getDeliveryNotesByIDAxios
} from "./services";

export async function getDeliveryNotesListAction({commit}, id) {
    commit(types.LOADING_DELIVERYNOTES, true);
    try {
        const {data} = await getDeliveryNotesListAxios(id);
        commit(types.GET_DELIVERYNOTES_LIST, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_DELIVERYNOTES, false);
}

export async function deleteDeliveryNotesAction({commit}, payload) {
    commit(types.LOADING_DELIVERYNOTES, true);
    try {
        await deleteDeliveryNotesAxios(payload);
        commit(types.REMOVE_DELIVERYNOTES, payload);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_DELIVERYNOTES, false);
}

export async function addDeliveryNotesAction({commit}, payload) {
    commit(types.LOADING_DELIVERYNOTES, true);
    try {
        const {data} = await postDeliveryNotesAxios(payload);
        payload.id = data.id;
        commit(types.ADD_DELIVERYNOTES, payload);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_DELIVERYNOTES, false);
}

export async function updateDeliveryNotesAction({commit}, payload) {
    commit(types.LOADING_DELIVERYNOTES, true);
    try {
        await putDeliveryNotesAxios(payload);        
        commit(types.UPDATE_DELIVERYNOTES, payload);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_DELIVERYNOTES, false);
}

export async function getDeliveryNotesByIDAction({commit}, payload) {
    commit(types.LOADING_DELIVERYNOTES, true);
    try {
        await getDeliveryNotesByIDAxios(payload);
        commit(types.GET_DELIVERYNOTESBYID, payload);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_DELIVERYNOTES, false);
}