import axios from 'axios';
import useJwt from '@/auth/jwt/useJwt';

const baseUrl = process.env.VUE_APP_API_URLv1;

let api = axios.create({
    baseURL: baseUrl,
});

// Add a request interceptor
api.interceptors.request.use(config => {
    const token = useJwt.getToken();
    const tenant = localStorage.getItem('userTenant');

    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['tenant'] = tenant;
    config.headers['Content-Type'] = 'application/json';

    return config;
}, error => {
    return Promise.reject(error);
});

export default api;
