
import * as types from "./types";

const mutations = { 
    [types.LOADING_SALESORDERS](state, value) {
        state.loading = value;
    },
    [types.GET_SALESORDERS_LIST](state, salesorders) {
        state.salesorders = salesorders;
    },
    [types.CHECK_INVENTORY](state, inventoryStatus) {
        state.inventoryStatus = inventoryStatus;
    },
    [types.REMOVE_SALESORDERS](state, id) {        
        state.salesorders = state.salesorders.filter((x) => x.id !== id);        
    },
    [types.ADD_SALESORDERS](state, salesorders) {        
        state.salesorders.unshift(salesorders);
        state.NewSalesOrder = salesorders;
    },
    [types.UPDATE_SALESORDERS](state, salesorders) {        
        const salesordersIndex = state.salesorders.findIndex((x) => x.id === salesorders.id);
        state.salesorders[salesordersIndex] = salesorders;        
    },
    [types.GET_SALESORDERSBYID](state, salesorderId) {
        state.salesorderId = salesorderId;
    },
    [types.GET_PRODUCTORDER](state, productorder) {
        state.productorder = productorder;
    },
    [types.LOADING_PRODUCTORDER](state, value) {
        state.loading = value;
    },
};

export default mutations;