import purchaseorders from ".";
import * as types from "./types";

const mutations = { 
    [types.LOADING_PAYMENT](state, value) {
        state.loading = value;
    },
    [types.GET_PAYMENT](state, payments) {
        state.payments = payments;
    },
    [types.REMOVE_PAYMENT](state, id) {        
        state.payments = state.payments.filter((x) => x.id !== id);        
    },
    [types.ADD_PAYMENT](state, payments) {        
        state.payments.payments.unshift(payments);
    },

};

export default mutations;