import quotation from ".";
import * as types from "./types";

const mutations = {
    [types.LOADING_QUOTATIONS](state, value) {
        state.loading = value;
    },
    [types.GET_QUOTATIONS_LIST](state, quotations) {
        state.quotations = quotations;
    },
    [types.REMOVE_QUOTATIONS](state, id) {        
        state.quotations = state.quotations.filter((x) => x.id !== id);        
    },
    [types.ADD_QUOTATIONS](state, quotation) {        
        state.quotations.unshift(quotation);
        state.newQuotation = quotation;
    },
    [types.UPDATE_QUOTATIONS](state, quotation) {        
        const quotationIndex = state.quotations.findIndex((x) => x.id === quotation.id);
        state.quotations[quotationIndex] = quotation;        
    },
    [types.GET_QUOTATION_ID](state, quotationId) {
        state.quotationId = quotationId;
    },
 };

 export default mutations;