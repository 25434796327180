import api from "@/api/api-v2-config";

export async function getItemCategoryListAxios() {    
    return await api.get("itemcategories?PageSize=1000&PageNumber=1");
}

export async function deleteItemCategoryAxios(id) {    
    return await api.delete("itemcategories?Id=" + id);
}

export async function postItemCategoryAxios(itemcategories) {    
    return await api.post("itemcategories", itemcategories);
}

export async function putItemCategoryAxios(itemcategories) {    
    return await api.put("itemcategories", itemcategories);
}

