import * as types from "./types";

import {
    getSalesOrderListAxios,
    deleteSalesOrdersAxios,
    postSalesOrdersAxios,
    putSalesOrdersAxios,
    getSalesOrdersByIDAxios,
    getProductOrderAxios,
    getInventoryCheckAxios
} from "./services";

export async function getSalesOrdersListAction({commit}) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        const {data} = await getSalesOrderListAxios();
        commit(types.GET_SALESORDERS_LIST, data.result.data);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
}

export async function checkInventoryAction({commit}, id) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        const {data} = await getInventoryCheckAxios(id);
        commit(types.CHECK_INVENTORY, data.result);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
}


export async function removeSalesOrdersAction({commit}, payload) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        await deleteSalesOrdersAxios(payload);
        commit(types.REMOVE_SALESORDERS, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
}

export async function addSalesOrdersAction({commit}, payload) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        const {data} = await postSalesOrdersAxios(payload);        
        commit(types.ADD_SALESORDERS, data.result);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
}

export async function updateSalesOrdersAction({commit}, payload) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        await putSalesOrdersAxios(payload);        
        commit(types.UPDATE_SALESORDERS, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
    
}

export async function getSalesOrdersByIDAction({commit}, payload) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        // await getSalesOrdersByIDAxios(payload);
        const {data} = await getSalesOrdersByIDAxios(payload);
        commit(types.GET_SALESORDERSBYID, data.result);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
}

export async function getProductOrderAction({commit}, payload) {
    commit(types.LOADING_SALESORDERS, true);
    try {
        await getProductOrderAxios(payload);
        commit(types.GET_PRODUCTORDER, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_SALESORDERS, false);
    }
}