import api from "@/api/api-v2-config";

export async function getTransactionListAxios() {    
    return await api.get("inventoryTransactions?pageNumber=1&pageSize=10000");
}

export async function getTransactionByIDAxios(id) {    
    return await api.get("inventoryTransactions/" + id);
}

export async function deleteTransactionAxios(id) {    
    return await api.delete("inventoryTransactions?id=" + id);
}

export async function postTransactionAxios(transaction) {    
    return await api.post("inventoryTransactions", transaction);
}

export async function putTransactionAxios(transaction) {    
    return await api.put("inventoryTransactions", transaction);
}