export default [
    // *===============================================---*
    // *--------- Equipments  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/equipment/equipment-list',
        name: 'equipment-list',
        component: () => import('@/views/equipment/Equipment.vue'),
        meta: {
            pageTitle: 'Equipment List',
            breadcrumb: [
                {
                    text: 'Equipment List',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  
        },
    },
]