import api from "@/api/api-v2-config";

export async function getTaskAxios() {    
    return await api.get("tasks");
}

export async function deleteTaskAxios(id) {    
    return await api.delete("tasks", id);
}

export async function postTaskAxios(item) {    
    return await api.post("tasks", item);
}

export async function putTaskAxios(item) {    
    return await api.put("tasks", item);
}