import api from "@/api/api-v2-config";

export async function getSupplierListAxios() {    
    return await api.get("suppliers?pageNumber=1&pageSize=10000000");
}

export async function deleteSupplierAxios(id) {    
    return await api.delete("suppliers?id=" + id);
}

export async function postSupplierAxios(supplier) {    
    return await api.post("suppliers", supplier);
}

export async function putSupplierAxios(supplier) {    
    return await api.put("suppliers", supplier);
}

export async function getSupplierByIDAxios(id) {    
    return await api.get("suppliers/" + id);
}