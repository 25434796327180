import * as types from "./types";

import { 
    getServiceRequestsListAxios,
    deleteServiceRequestsAxios,
    postServiceRequestsAxios,
    putServiceRequestsAxios,
    getServiceRequestsByIDAxios,
} from "./services";

export async function getServiceRequestListAction({commit}) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        const {data} = await getServiceRequestsListAxios();
        commit(types.GET_SERVICEREQUESTS_LIST, data.result.data);
    }
    catch(e) {
        throw e;
     }
    finally{
     commit(types.LOADING_SERVICEREQUESTS, false);
    }
}

export async function removeServiceRequestsAction({commit}, payload) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        await deleteServiceRequestsAxios(payload);
        commit(types.REMOVE_SERVICEREQUESTS, payload);
    }
    catch(e) {
        throw e;
     }
    finally{
     commit(types.LOADING_SERVICEREQUESTS, false);
    }
}

export async function addServiceRequestsAction({commit}, payload) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        const {data} = await postServiceRequestsAxios(payload);
        payload.id = data.id;
        commit(types.ADD_SERVICEREQUESTS, payload);
    }
    catch(e) {
        throw e;
     }
    finally{
     commit(types.LOADING_SERVICEREQUESTS, false);
    }
  }

export async function updateServiceRequestAction({commit}, payload) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        await putServiceRequestsAxios(payload);        
        commit(types.UPDATE_SERVICEREQUESTS, payload);
    }
    catch(e) {
        throw e;
     }
    finally{
     commit(types.LOADING_SERVICEREQUESTS, false);
    }
}

export async function getServiceRequestsByIDAction({commit}, payload) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        const {data} = await getServiceRequestsByIDAxios(payload);
        commit(types.GET_SERVICEREQUESTSBYID, data.result);
    }
    catch(e) {
       throw e;
    }
   finally{
    commit(types.LOADING_SERVICEREQUESTS, false);
   }
}

export async function approveService({commit}, payload) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        const {data} = await getServiceRequestsByIDAxios(payload);
        commit(types.GET_SERVICEREQUESTSBYID, data.result);
    }
    catch(e) {
       throw e;
    }
   finally{
    commit(types.LOADING_SERVICEREQUESTS, false);
   }
}