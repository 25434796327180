import * as types from "./types";
import {
    getSupplierListAxios,
    deleteSupplierAxios,
    postSupplierAxios,
    putSupplierAxios,
    getSupplierByIDAxios
} from "./services";

export async function getSupplierListAction({ commit }) {
    commit(types.LOADING_SUPPLIER, true);
    try {
        const { data } = await getSupplierListAxios();
        commit(types.GET_SUPPLIER_LIST, data.result.data);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_SUPPLIER, false);
    }
}

export async function removeSupplierAction({ commit }, payload) {
    commit(types.LOADING_SUPPLIER, true);
    try {
        await deleteSupplierAxios(payload);
        commit(types.REMOVE_SUPPLIER, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_SUPPLIER, false);
    }
}

export async function addSupplierAction({ commit }, payload) {
    commit(types.LOADING_SUPPLIER, true);
    try {
        await postSupplierAxios(payload);
        commit(types.ADD_SUPPLIER, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_SUPPLIER, false);
    }
}

export async function updateSupplierAction({ commit }, payload) {
    commit(types.LOADING_SUPPLIER, true);
    try {
        await putSupplierAxios(payload);
        commit(types.UPDATE_SUPPLIER, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_SUPPLIER, false);
    }
}


export async function getSupplierByIDAction({commit}, payload) {
    commit(types.LOADING_SUPPLIER, true);
    try {
        
        const {data} = await getSupplierByIDAxios(payload);
        commit(types.GET_SUPPLIERBYID, data.result);
    }
    catch(e) {
        throw e;
    }
    finally {
        commit(types.LOADING_SUPPLIER, false);
    }
   
}