import api from "@/api/api-v2-config";

export async function getLeadListAxios() {    
    return await api.get("leads?pageNumber=1&pageSize=10000");
}

export async function deleteLeadAxios(id)
 {    
    return await api.delete("leads/" + id);
}

export async function postLeadAxios(lead) {    
    return await api.post("leads", lead);
}

export async function putLeadAxios(lead) {    
    return await api.put("leads", lead);
}

export async function getLeadByIDAxios(id)
 {    
    return await api.get("leads/" + id);
}

export async function getLeadQuotationsAxios(id)
 {    
    return await api.get("leads/" + id + "/quotes");
}

export async function getLeadContactPersonAxios(id)
 {    
    return await api.get("leads/" + id + "/contacts?pageNumber=1&pageSize=1000");
}

export async function getLeadConvertAxios(id)
 {    
    return await api.post("leads/" + id + "/convert");
}



