import * as types from "./types";

const mutations = {
    [types.LOADING_PERMISSIONS](state, value) {
        state.loading = value;
    },
    [types.GET_PERMISSIONS_LIST](state, permissions) {
        state.permissions = permissions;
    },
  
};

export default mutations;