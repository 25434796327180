import * as types from "./types";

import {
    getPurchaseOrderListAxios,
    deletePurchaseOrdersAxios,
    postPurchaseOrdersAxios,
    putPurchaseOrdersAxios,
    getPurchaseOrdersByIDAxios
} from "./services";

export async function getPurchaseOrdersListAction({ commit }) {
    commit(types.LOADING_PURCHASEORDERS, true);
    try {
        const { data } = await getPurchaseOrderListAxios();
        commit(types.GET_PURCHASEORDERS_LIST, data.result.data);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_PURCHASEORDERS, false);
    }
}

export async function removePurchaseOrdersAction({ commit }, payload) {
    commit(types.LOADING_PURCHASEORDERS, true);
    try {
        await deletePurchaseOrdersAxios(payload);
        commit(types.REMOVE_PURCHASEORDERS, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_PURCHASEORDERS, false);
    }
}

export async function addPurchaseOrdersAction({ commit }, payload) {
    commit(types.LOADING_PURCHASEORDERS, true);
    try {
        await postPurchaseOrdersAxios(payload);
        commit(types.ADD_PURCHASEORDERS, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_PURCHASEORDERS, false);
    }
}

export async function updatePurchaseOrdersAction({ commit }, payload) {
    commit(types.LOADING_PURCHASEORDERS, true);
    try {
        await putPurchaseOrdersAxios(payload);
        commit(types.UPDATE_PURCHASEORDERS, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_PURCHASEORDERS, false);
    }
}

export async function getPurchaseOrdersByIDAction({ commit }, payload) {
    commit(types.LOADING_PURCHASEORDERS, true);
    try {
        // await getSalesOrdersByIDAxios(payload);
        // commit(types.GET_SALESORDERSBYID, payload);
        const { data } = await getPurchaseOrdersByIDAxios(payload);
        commit(types.GET_PURCHASEORDERSBYID, data.result);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_PURCHASEORDERS, false);
    }
}
