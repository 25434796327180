import * as types from "./types";

const mutations = {
    [types.LOADING_INVENTORY_TRANSACTION](state, value) {
        state.loading = value;
    },
    [types.GET_INVENTORY_TRANSACTION_LIST](state, transactions) {
        state.transactions = transactions;
    },    
    [types.REMOVE_INVENTORY_TRANSACTION](state, id) {        
        state.transactions = state.transactions.filter((x) => x.id !== id);        
    },
    [types.ADD_INVENTORY_TRANSACTION](state, transaction) {        
        state.transactions.unshift(transaction);
    },
    [types.UPDATE_INVENTORY_TRANSACTION](state, transaction) {        
        const index = state.transactions.findIndex((x) => x.id === transaction.id);
        state.transactions[index] = transaction;        
    }    
};

export default mutations;