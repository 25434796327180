import api from "@/api/api-v1-config";

export async function getUserListAxios() {
    return await api.get("users");
}

export async function getUserByIdAxios(id) {
    return await api.get("users/" + id);
}

export async function getUserRoleAxios(id) {
    return await api.get("users/" + id + "/roles");
}


export async function postUserRoleAxios(id, user) {
    return await api.post("users/" + id + "/roles", user);
}


export async function postUserAxios(user) {
    return await api.post("users", user);
}

