import * as types from "./types";

const mutations = {
    [types.LOADING_MARKETING](state, value) {
        state.loading = value;
    },
    [types.GET_MARKETING](state, appid) {
        state.appid = appid;
    },
    [types.POST_MARKETING_ID](state, appid) {        
        state.appid.unshift(appid);
    },

    [types.GET_POSTS](state, posts) {
        state.posts = posts;
    },
    [types.REMOVE_POST](state, id) {        
        state.posts = state.posts.filter((x) => x.id !== id);        
    },
    
};

export default mutations;