export default [
    // *===============================================---*
    ///SERVICES ///
    {
        path: '/pipeline',
        name: 'pipeline',
        component: () => import('@/views/sales/pipeline/main.vue'),
        meta: {
            pageTitle: 'Pipeline',
            breadcrumb: [
                {
                    text: 'Pipeline',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
]