import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'


const baseUrl = process.env.VUE_APP_API_URL




let token =  useJwt.getToken()
let tenant = localStorage.getItem('userTenant');
let headers = {
    'Content-Type': 'application/json',
    'tenant': tenant    
}

axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
let api = axios.create({ 
    baseURL: baseUrl,
    headers: headers
}); 

export default api;