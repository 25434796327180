import * as types from "./types";

const mutations = {
    [types.LOADING_FILE](state, value) {
        state.loading = value;
    },
    [types.GET_FILE_LIST](state, files) {
        state.files = files;
    },
    [types.REMOVE_FILE](state, id) {        
        state.files = state.files.filter((x) => x.id !== id);        
    },
    [types.ADD_FILE](state, file) {        
        state.files.unshift(file);
    },
    [types.UPDATE_FILE](state, file) {        
        const fileIndex = state.files.findIndex((x) => x.id === file.id);
        state.files[fileIndex] = file;        
    }
};

export default mutations;