import api from "@/api/api-v2-config";

export async function getPurchaseOrderListAxios() {    
    return await api.get("purchaseorders?pageNumber=1&pageSize=10000");
}

export async function deletePurchaseOrdersAxios(id) {    
    return await api.delete("purchaseorders?Id=" + id);
}

export async function postPurchaseOrdersAxios(purchaseorders) {    
    return await api.post("purchaseorders", purchaseorders);
}

export async function putPurchaseOrdersAxios(purchaseorders) {    
    return await api.put("purchaseorders", purchaseorders);
}

export async function getPurchaseOrdersByIDAxios(id) {    
    return await api.get("purchaseorders/" + id);
}

// export async function getProductOrderAxios(productorder) {    
//     return await api.get("purchaseorders/getproductorder", productorder);
// }