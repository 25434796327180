import * as types from "./types";

const mutations = {
    [types.LOADING_TENANT](state, value) {
        state.loading = value;
    },
    [types.GET_TENANT_LIST](state, tenants) {
        state.tenants = tenants;
    },
    [types.UPDATE_TENANT](state, tenant) {        
        const TenantIndex = state.tenants.findIndex((x) => x.id === tenant.id);
        state.tenants[TenantIndex] = tenant;        
    },
    [types.GET_TENANT_ID](state, tenantsId) {
        state.tenantsId = tenantsId;
    },
    [types.ADD_TENANT](state, tenants) {
        state.tenants.unshift(tenants);
    },
};

export default mutations;