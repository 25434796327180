export default [
    {
        path: "/itemcategories",
        name: "itemcategories",
        component: () => import("@/views/category/CategoryView.vue"),
        meta: {
            pageTitle: 'Category List',
            permission: 'Permissions.Inventory.View'  
        },
    }
]