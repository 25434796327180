import * as types from "./types";
import {
    getJobcardListAxios,
    deleteJobcardAxios,
    postJobcardAxios,
    putJobcardAxios,
    getJobcardIdAxios,
    getJobcardDemandAxios,
    putJobcardStatusAxios,
    putMultipleJobCard
} from "./services";


export async function updateJobcardAction({ commit }, payload) {
    commit(types.LOADING_JOBCARD, true);
    try {
        await putJobcardAxios(payload);
        commit(types.UPDATE_JOBCARD, payload);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}

export async function getJobcardIdAction({ commit }, payload) {
    commit(types.LOADING_JOBCARD, true);
    try {
        const { data } = await getJobcardIdAxios(payload);
        commit(types.GET_JOBCARD_ID, data.result);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}

export async function getJobcardDemandAction({ commit }) {
    commit(types.LOADING_JOBCARD, true);
    try {
        const { data } = await getJobcardDemandAxios();
        commit(types.GET_JOBCARD_DEMAND, data.result.data);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}

export async function getJobcardListAction({ commit }) {
    commit(types.LOADING_JOBCARD, true);
    try {
        const { data } = await getJobcardListAxios();
        commit(types.GET_JOBCARD_LIST, data.result.data);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }

}

export async function removeJobcardAction({ commit }, payload) {
    commit(types.LOADING_JOBCARD, true);
    try {
        await deleteJobcardAxios(payload);
        commit(types.REMOVE_JOBCARD, payload);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}

export async function addJobcardAction({ commit }, payload) {
    commit(types.LOADING_JOBCARD, true);
    try {
        const { data } = await postJobcardAxios(payload);
        payload.id = data.id;
        payload.contactPersons = [];
        commit(types.ADD_JOBCARD, payload);
    }
    catch (e) {

        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}

export async function updateJobcardStatusAction({ commit }, payload) {
    commit(types.LOADING_JOBCARD, true);
    try {
        await putJobcardStatusAxios(payload);
        commit(types.UPDATE_JOBCARD_STATUS, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}

export async function updateMultipleJobCardAction({ commit }, payload) {
    commit(types.LOADING_JOBCARD, true);
    try {
        await putMultipleJobCard(payload);
        commit(types.UPDATE_MULTIPLE_JOBCARD, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_JOBCARD, false);
    }
}