import * as types from "./types";
import { 
    getSalesPersonListAxios
} from "./services";

export async function getSalesPersonListAction({commit}) {
    commit(types.LOADING_SALESPERSON, true);
    try {
        const {data} = await getSalesPersonListAxios();
        commit(types.GET_SALESPERSON_LIST, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_SALESPERSON, false);
}
