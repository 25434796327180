import * as types from "./types";
import { 
    getRawMaterialListAxios, 
    deleteRawMaterialAxios,
    postRawMaterialAxios,
    putRawMaterialAxios,
    getRawMaterialListByIdAxios
} from "./services";

export async function getRawMaterialListAction({commit}) {
    commit(types.LOADING_RAWMATERIAL, true);
    try {
        const {data} = await getRawMaterialListAxios();
        commit(types.GET_RAWMATERIAL_LIST, data.result.data);
    }
    catch(e) {
       throw e;
    }
 finally{
    commit(types.LOADING_RAWMATERIAL, false);
 }
}

export async function getRawMaterialListByIdAction({commit}, payload) {
    commit(types.LOADING_RAWMATERIAL, true);
    try {
        const {data} = await getRawMaterialListByIdAxios(payload);
        commit(types.GET_RAWMATERIAL_ID, data.result);
    }
    catch(e) {
        throw e;
     }
  finally{
     commit(types.LOADING_RAWMATERIAL, false);
  }
}

export async function removeRawMaterialAction({commit}, payload) {
    commit(types.LOADING_RAWMATERIAL, true);
    try {
        await deleteRawMaterialAxios(payload);
        commit(types.REMOVE_RAWMATERIAL, payload);
    }
    catch(e) {
        throw e;
     }
  finally{
     commit(types.LOADING_RAWMATERIAL, false);
  }
}

export async function addRawMaterialAction({commit}, payload) {
    commit(types.LOADING_RAWMATERIAL, true);
    try {
        const data = await postRawMaterialAxios(payload);
        payload.id = data.data.result.id;
        commit(types.ADD_RAWMATERIAL, payload);
    }
    catch(e) {
        throw e;
     }
  finally{
     commit(types.LOADING_RAWMATERIAL, false);
  }
}

export async function updateRawMaterialAction({commit}, payload) {
    commit(types.LOADING_RAWMATERIAL, true);
    try {
        await putRawMaterialAxios(payload);        
        commit(types.UPDATE_RAWMATERIAL, payload);
    }
    catch(e) {
        throw e;
     }
  finally{
     commit(types.LOADING_RAWMATERIAL, false);
  }
}