import * as types from "./types";
import { 
    getWarehouseAxios, 
    deleteWarehouseAxios,
    postWarehouseAxios,
    putWarehouseAxios
} from "./services";

export async function getWarehouseListAction({commit}) {
    commit(types.LOADING_WAREHOUSE, true);
    try {
        const {data} = await getWarehouseAxios();
        commit(types.GET_WAREHOUSE_LIST, data.result.data);
    }
    catch(e) {
    
        throw e;
    }
  finally{
    commit(types.LOADING_WAREHOUSE, false);
  }
}

export async function removeWarehouseAction({commit}, payload) {
    commit(types.LOADING_WAREHOUSE, true);
    try {
        await deleteWarehouseAxios(payload);
        commit(types.REMOVE_WAREHOUSE, payload);
    }
    catch(e) {
    
        throw e;
    }
  finally{
    commit(types.LOADING_WAREHOUSE, false);
  }
}

export async function addWarehouseAction({commit}, payload) {
    commit(types.LOADING_WAREHOUSE, true);
    try {
        await postWarehouseAxios(payload);
        commit(types.ADD_WAREHOUSE, payload);
    }
    catch(e) {
    
        throw e;
    }
  finally{
    commit(types.LOADING_WAREHOUSE, false);
  }
}

export async function updateWarehouseAction({commit}, payload) {
    commit(types.LOADING_WAREHOUSE, true);
    try {
        await putWarehouseAxios(payload);        
        commit(types.UPDATE_WAREHOUSE, payload);
    }
    catch(e) {
    
        throw e;
    }
  finally{
    commit(types.LOADING_WAREHOUSE, false);
  }
}