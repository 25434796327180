

export default [
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/product/list',
        name: 'product-list',
        component: () => import('@/views/inventory/products/ProductList.vue'),
        meta: {
          pageTitle: 'Products List',
          breadcrumb: [
            {
              text: 'Products List',
              active:true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
        },
      },
      
      {
        path: '/product/add',
        name: 'product-add',
        component: () => import('@/views/inventory/products/AddProduct/addproductwizard.vue'),
        meta: {
          pageTitle: 'Add Product',
          breadcrumb: [
            {
              text: 'Product List',
              to : '/product/list'
            },
            {
              text: 'Add Product',
              active: true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
        },
      },
      {
        path: '/product/view/:id',
        name: 'product-view',
        component: () => import('@/views/inventory/products/ViewProduct.vue'),
        meta: {
          pageTitle: 'View Product',
          breadcrumb: [
            {
              text: 'Product List',
              to : '/product/list'
            },
            {
              text: 'View Product',
              active: true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
        },
      },
      {
        path: '/product/edit/:id',
        name: 'product-edit',
        component: () => import('@/views/inventory/products/EditProduct/EditProductWizard.vue'),
        meta: {
          pageTitle: 'View Product',
          breadcrumb: [
            {
              text: 'Product List',
              to : '/product/list'
            },
            {
              text: 'View Product',
              active: true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
        },
      },
      {
        path: '/product/stockTransaction/:id',
        name: 'product-stockTransaction',
        component: () => import('@/views/inventory/products/updateStock.vue'),
        meta: {
          pageTitle: 'Product Stock Transactions',
          breadcrumb: [
            {
              text: 'Product List',
              to : '/product/list'
            },
            {
              text: 'Stock Transactions ',
              active: true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
          
        },
      },
      {
        path: '/rawmaterials/list',
        name: 'rawmaterials-list',
        component: () => import('@/views/rawmaterials/ProductList.vue'),
        meta: {
          pageTitle: 'Raw Materials',
          breadcrumb: [
            {
              text: 'Raw Materials List',
              active:true,
            },
          ],
        },
        permission: 'Permissions.Inventory.View'  
      },

      {
        path: '/rawmaterials/add',
        name: 'rawmaterials-add',
        component: () => import('@/views/rawmaterials/AddProduct/addproduct.vue'),
        meta: {
          pageTitle: 'Add Raw Materials',
          breadcrumb: [
            {
              text: 'Raw Materials List',
              to : '/rawmaterials/list'
            },
            {
              text: 'Add Raw Materials',
              active: true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
        },
      },
      {
        path: '/rawmaterials/edit',
        name: 'rawmaterials-edit',
        component: () => import('@/views/rawmaterials/EditProduct.vue'),
        meta: {
          pageTitle: 'Edit Raw Materials',
          breadcrumb: [
            {
              text: 'Raw Materials List',
              to : '/rawmaterials/list'
            },
            {
              text: 'Edit Raw Materials',
              active: true,
            },
          ],
          permission: 'Permissions.Inventory.View'  
        },
      },
     

    



      {
        path: '/rawmaterials/view/:id',
        name: 'rawmaterial-view',
        component: () => import('@/views/rawmaterials/AddProduct/ViewRawMaterial.vue'),
        meta: {
          pageTitle: 'View Raw Material',
          breadcrumb: [
            {
              text: 'Raw Material List',
              to : '/rawmaterials/list'
            },
            {
              text: 'View Raw Material',
              active: true,
            },
          ],
          
        },
      },
      {
        path: '/rawmaterials/edit/:id',
        name: 'rawmaterial-edit',
        component: () => import('@/views/rawmaterials/EditProduct.vue'),
        meta: {
          pageTitle: 'Edit Raw Material',
          breadcrumb: [
            {
              text: 'Raw Material List',
              to : '/rawmaterials/list'
            },
            {
              text: 'Edit Raw Material',
              active: true,
            },
          ],
          
        },
      },
      {
        path: '/rawmaterials/stockTransaction/:id',
        name: 'rawmaterial-stockTransaction',
        component: () => import('@/views/rawmaterials/AddProduct/updateStock.vue'),
        meta: {
          pageTitle: 'Raw Materials Stock Transactions',
          breadcrumb: [
            {
              text: 'Raw Material List',
              to : '/rawmaterials/list'
            },
            {
              text: 'Stock Transactions ',
              active: true,
            },
          ],
          
        },
      },
   

]