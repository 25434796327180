const state = {
    customers: [],
    custId: [],
    contacts: [],
    quotation: [],
    invoice: [],
    order: [],
    loading: false
};

export default state;