

export default [
    // *===============================================---*
    {
        path: '/invoice/list',
        name: 'invoice-list',
        component: () => import('@/views/sales/invoices/Invoices.vue'),
        meta: {
            pageTitle: 'Invoices List',
            breadcrumb: [
                {
                    text: 'Invoices List',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'

        },
    },

    {
        path: '/invoice/view/:id',
        name: 'invoice-list',
        component: () => import('@/views/sales/invoices/ViewInvoice.vue'),
        meta: {
            pageTitle: 'View Invoice',
            breadcrumb: [
                {
                    text: 'Invoices List',
                    to: '/invoice/list',
                },
                {
                    text: 'View Invoice',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'

        },
    },
]

