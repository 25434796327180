import * as types from "./types";

const mutations = {
    [types.LOADING_ITEMCATEGORY](state, value) {
        state.loading = value;
    },
    [types.GET_ITEMCATEGORY_LIST](state, itemcategories) {
        state.itemcategories = itemcategories;
    },
    [types.REMOVE_ITEMCATEGORY](state, id) {        
        state.itemcategories = state.itemcategories.filter((x) => x.id !== id);        
    },
    [types.ADD_ITEMCATEGORY](state, itemcategories) {        
        state.itemcategories.unshift(itemcategories);
    },
    [types.UPDATE_ITEMCATEGORY](state, itemcategories) {        
        const itemCategoryIndex = state.itemcategories.findIndex((x) => x.id === itemcategories.id);
        state.itemcategories[itemCategoryIndex] = itemcategories;        
    }
};

export default mutations;