import api from "@/api/api-v2-config";

export async function getRawMaterialListAxios() {    
    return await api.get("rawmaterials?pageNumber=1&pageSize=1000");
}

export async function getRawMaterialListByIdAxios(id){
    return await api.get("rawmaterials/"+id);
}

export async function deleteRawMaterialAxios(id) {    
    return await api.delete("rawmaterials?Id=" + id);
}

export async function postRawMaterialAxios(rawmaterials) {    
    return await api.post("rawmaterials", rawmaterials);
}

export async function putRawMaterialAxios(rawmaterials) {    
    return await api.put("rawmaterials", rawmaterials);
}