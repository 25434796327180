import * as types from "./types";
import { 
    getProductAxios, 
    getProductIDAxios,
    deleteProductAxios,
    postProductAxios,
    putProductAxios,
    postProductFileAxios
} from "./services";

export async function getProductListAction({commit}) {
    commit(types.LOADING_PRODUCT, true);
    try {
        const {data} = await getProductAxios();
        commit(types.GET_PRODUCT_LIST, data.result.data);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_PRODUCT, false);
    }
   
}

export async function getProductIDAction({commit},payload) {
    commit(types.LOADING_PRODUCT, true);
    try {
        const {data} = await getProductIDAxios(payload);
        commit(types.GET_PRODUCT_ID, data.result);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_PRODUCT, false);
    }
}

export async function removeProductAction({commit}, payload) {
    commit(types.LOADING_PRODUCT, true);
    try {
        await deleteProductAxios(payload);
        commit(types.REMOVE_PRODUCT, payload);
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_PRODUCT, false);
    }
}

export async function addProductAction({commit}, payload) {
    commit(types.LOADING_PRODUCT, true);
    try {
        const data = await postProductAxios(payload.product);
        payload.product.id = data.data.id;        
        commit(types.ADD_PRODUCT, payload.product);        
        if(payload.file) {            
            await postProductFileAxios({itemId: payload.product.id, file: payload.file});
        }        
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_PRODUCT, false);
    }
}

export async function updateProductAction({commit}, payload) {
    commit(types.LOADING_PRODUCT, true);
    try {
        const data = await putProductAxios(payload.product);
        payload.product.id = data.data.result.id;        
        commit(types.UPDATE_PRODUCT, payload.product);        
        if(payload.file) {            
            await postProductFileAxios({itemId: payload.product.id, file: payload.file});
        }        
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_PRODUCT, false);
    }
}

export async function addProductFileAction({commit}, payload) {
    commit(types.LOADING_PRODUCT, true);
    try {
        await postProductFileAxios(payload);                
    }
    catch(e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_PRODUCT, false);
    }
}