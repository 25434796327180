import * as types from "./types";
import {
    getUserListAxios,
    getUserByIdAxios,
    postUserAxios,
    getUserRoleAxios,
    postUserRoleAxios,


} from "./services";

export async function getUserListAction({ commit }) {
    commit(types.LOADING_USER, true);
    try {
        const { data } = await getUserListAxios();
        commit(types.GET_USER_LIST, data.result);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
   finally {
        commit(types.LOADING_USER, false);
    }
}

export async function getUserIdAction({ commit }, payload) {
    commit(types.LOADING_USER, true);
    try {
        const { data } = await getUserByIdAxios(payload);
        commit(types.GET_USER_ID, data.result);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_USER, false);
    }
}


export async function getUserRoleAction({ commit }, payload) {
    commit(types.LOADING_USER, true);
    try {
        const { data } = await getUserRoleAxios(payload);
        commit(types.GET_ROLE, data.result);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_USER, false);
    }
}


export async function addUserAction({ commit }, payload) {
    commit(types.LOADING_USER, true);
    try {
        await postUserAxios(payload);
        commit(types.ADD_USER, payload);
    }
    catch (e) {
       
        throw e;
    }
    finally {
        commit(types.LOADING_USER, false);
    }
}


export async function addUserRoleAction({ commit }, id, payload) {
    commit(types.LOADING_USER, true);
    try {
        await postUserRoleAxios(id, payload);
        commit(types.ADD_USER_ROLE, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_USER, false);
    }
}
