import api from "@/api/api-v2-config";

export async function getProductAxios() {    
    return await api.get("products?PageSize=1000&PageNumber=1");
}

export async function getProductIDAxios(id) {    
    return await api.get("products/"+ id);
}

export async function deleteProductAxios(id) {    
    return await api.delete("products?ProductId=" + id);
}

export async function postProductAxios(item) {    
    return await api.post("products", item);
}

export async function putProductAxios(item) {    
    return await api.put("products", item);
}

export async function postProductFileAxios(data) {    
    let payload = new FormData();
    payload.append("ItemId", data.itemId);
    payload.append("Image", data.file);
    api.defaults.headers["Content-Type"] = data.file.type;
    const res = await api.post("products/productimage", payload);
    api.defaults.headers["Content-Type"] = "application/json";
    return res;
}