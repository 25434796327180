export default [
    // *===============================================---*
    ///SERVICES ///
    {
        path: '/opportunity/list',
        name: 'opportunity-list',
        component: () => import('@/views/sales/opportunity/main.vue'),
        meta: {
            pageTitle: 'Opportunity List',
            breadcrumb: [
                {
                    text: 'Opportunity List',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },


    {
        path: '/opportunity/add',
        name: 'opportunity-add',
        component: () => import('@/views/sales/opportunity/add.vue'),
        meta: {
            pageTitle: 'Add Opportunity',
            breadcrumb: [
                {
                    text: 'Opportunity List',
                    to: '/opportunity/list'
                },
                {
                    text: 'Add Opportunity',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },
    // correct code 
    // {
    //     path: '/opportunity/view/:id',
    //     name: 'opportunity-view',
    //     component: () => import('@/views/sales/opportunity/ViewOpportunity.vue'),
    //     meta: {
    //         pageTitle: 'View Opportunity',
    //         breadcrumb: [
    //             {
    //                 text: 'Opportunity List',
    //                 to: '/opportunity/list'
    //             },
    //             {
    //                 text: 'View Opportunity',
    //                 active: true,
    //             },
    //         ],
    //         permission: 'Permissions.Sales.View'
    //     },
    // },
// dummy code
{
    path: '/opportunity/view',
    name: 'opportunity-view',
    component: () => import('@/views/sales/opportunity/ViewOpportunity.vue'),
    meta: {
        pageTitle: 'View Opportunity',
        breadcrumb: [
            {
                text: 'Opportunity List',
                to: '/opportunity/list'
            },
            {
                text: 'View Opportunity',
                active: true,
            },
        ],
        permission: 'Permissions.Sales.View'
    },
},

    // {
    //     path: '/quotation/add',
    //     name: 'quotation-add',
    //     component: () => import('@/views/sales/quotation/AddQuote.vue'),
    //     meta: {
    //         pageTitle: 'Add Quotation',
    //         breadcrumb: [
    //             {
    //                 text: 'Quotations List',
    //                 to: '/quotation/list'
    //             },
    //             {
    //                 text: 'Add Quotation',
    //                 active: true,
    //             },
    //         ],
    //         permission: 'Permissions.Sales.View'

    //     },
    // },

]