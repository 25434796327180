import * as types from "./types";
import {
    getItemBalanceListAxios,
    getItemBalanceByIDAxios,
    deleteItemBalanceAxios,
    postItemBalanceAxios,
    putItemBalanceAxios    
} from "./services";

export async function getItemBalanceListAction({ commit }) {
    commit(types.LOADING_ITEM_BALANCE, true);
    try {
        const { data } = await getItemBalanceListAxios();
        commit(types.GET_ITEM_BALANCE_LIST, data.result.data);
    }
    catch (e) {
        console.log(e);
    }
    finally {
        commit(types.LOADING_ITEM_BALANCE, false);
    }
}

export async function getItemBalanceByIdAction({ commit }, payload) {
    commit(types.LOADING_ITEM_BALANCE, true);
    try {
        const { data } = await getItemBalanceByIDAxios(payload);
        return data.result;
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_ITEM_BALANCE, false);
    }
}

export async function removeItemBalanceAction({ commit }, payload) {
    commit(types.LOADING_ITEM_BALANCE, true);
    try {
        await deleteItemBalanceAxios(payload);
        commit(types.REMOVE_ITEM_BALANCE, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_ITEM_BALANCE, false);
    }
}

export async function addItemBalanceAction({ commit }, payload) {
    commit(types.LOADING_ITEM_BALANCE, true);
    try {
        const { data } = await postItemBalanceAxios(payload);
        payload.id = data.result.id;        
        commit(types.ADD_ITEM_BALANCE, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_ITEM_BALANCE, false);
    }
}

export async function updateItemBalanceAction({ commit }, payload) {
    commit(types.LOADING_ITEM_BALANCE, true);
    try {
        await putItemBalanceAxios(payload);
        commit(types.UPDATE_ITEM_BALANCE, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_ITEM_BALANCE, false);
    }
}