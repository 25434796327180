import * as types from "./types";

import {
    getCustomersListAxios,
    deleteCustomersAxios,
    postCustomersAxios,
    putCustomersAxios,
    getCustomersByIDAxios,
    getOrdersByIDAxios,
    getQuotationsByIDAxios,
    getServiceRequestsByIDAxios,
    getInvoicesByIDAxios
} from "./services";

export async function getCustomersListAction({ commit }) {
    commit(types.LOADING_CUSTOMERS, true);
    try {
        const { data } = await getCustomersListAxios();
        commit(types.GET_CUSTOMERS_LIST, data.result);
    }
    catch (e) {
        throw e;
     }
   
     finally {
         commit(types.LOADING_CUSTOMERS, false);
     }
}

export async function deleteCustomersAction({ commit }, payload) {
    commit(types.LOADING_CUSTOMERS, true);
    try {
        await deleteCustomersAxios(payload);
        commit(types.REMOVE_CUSTOMERS, payload);
    }
    catch (e) {
        throw e;
     }
   
     finally {
         commit(types.LOADING_CUSTOMERS, false);
     }
}

export async function addCustomersAction({ commit }, payload) {
    commit(types.LOADING_CUSTOMERS, true);
    try {
        const { data } = await postCustomersAxios(payload);
        payload.id = data.id;
        commit(types.ADD_CUSTOMERS, payload);
    }
    catch (e) {
        throw e;
     }
   
     finally {
         commit(types.LOADING_CUSTOMERS, false);
     }
}

export async function updateCustomersAction({ commit }, payload) {
    commit(types.LOADING_CUSTOMERS, true);
    try {
        await putCustomersAxios(payload);
        commit(types.UPDATE_CUSTOMERS, payload);
    }
    catch (e) {
       throw e;
    }
  
    finally {
        commit(types.LOADING_CUSTOMERS, false);
    }
}

export async function getCustomersByIDAction({ commit }, payload) {
    commit(types.LOADING_CUSTOMERS, true);
    try {
        const { data } = await getCustomersByIDAxios(payload);
        commit(types.GET_CUSTOMERSBYID, data.result);
    }
    catch (e) {
        throw e;
    }
    finally{
        commit(types.LOADING_CUSTOMERS, false);
    }

}

export async function getOrdersByIDAction({ commit }, payload) {
    commit(types.LOADING_ORDERS, true);
    try {
        const { data } = await getOrdersByIDAxios(payload);
        commit(types.GET_ORDERSBYID, data.result);
    }
    catch (e) {
        throw e;
     }
   
     finally {
         commit(types.LOADING_ORDERS, false);
     }
}

export async function getQuotationsByIDAction({ commit }, payload) {
    commit(types.LOADING_QUOTATIONS, true);
    try {
        const { data } = await getQuotationsByIDAxios(payload);
        commit(types.GET_QUOTATIONSBYID, data.result);
    }
    catch (e) {
        console.log(e);
    }
    commit(types.LOADING_QUOTATIONS, false);
}

export async function getServiceRequestsByIDAction({ commit }, payload) {
    commit(types.LOADING_SERVICEREQUESTS, true);
    try {
        await getServiceRequestsByIDAxios(payload);
        commit(types.GET_SERVICEREQUESTSBYID, payload);
    }
    catch (e) {
        console.log(e);
    }
    commit(types.LOADING_SERVICEREQUESTS, false);
}

export async function getInvoicesByIDAction({ commit }, payload) {
    commit(types.LOADING_CUSTOMERS, true);
    try {
        const { data } = await getInvoicesByIDAxios(payload);
        commit(types.GET_INVOICESBYID, data.result);
    }
    catch (e) {
        console.log(e);
    }
    commit(types.LOADING_CUSTOMERS, false);
}