export default [
    {
        path: '/monitoring/plan/calender',
        name: 'monitoring-plan-calender',
        component: () => import('@/views/production/monitoring/Calendar.vue'),
        meta:{
            pageTitle: 'Plan & Monitor',
            breadcrumb: [
                {
                  text:'Plan & Monitor',
                  active:true,
                },
            ],
            permission: 'Permissions.Production.View'  
        },
    },
]