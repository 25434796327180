import api from "@/api/api-v2-config";

export async function getItemUOMListAxios() {    
    return await api.get("itemuoms");
}

export async function deleteItemUOMAxios(id) {    
    return await api.delete("itemuoms?Id=" + id);
}

export async function postItemUOMAxios(item) {    
    return await api.post("itemuoms", item);
}

export async function putItemUOMAxios(item) {    
    return await api.put("itemuoms", item);
}