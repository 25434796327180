import * as types from "./types";

const mutations = {
    [types.LOADING_EQUIPMENT](state, value) {
        state.loading = value;
    },
    [types.GET_EQUIPMENT_LIST](state, equipments) {
        state.equipments = equipments;
    },
    [types.REMOVE_EQUIPMENT](state, id) {        
        state.equipments = state.equipments.filter((x) => x.id !== id);        
    },
    [types.ADD_EQUIPMENT](state, equipment) {        
        state.equipments.unshift(equipment);
    },
    [types.UPDATE_EQUIPMENT](state, equipment) {        
        const equipmentIndex = state.equipments.findIndex((x) => x.id === equipment.id);
        state.equipments[equipmentIndex] = equipment;        
    }
};

export default mutations;