export default [

    // *===============================================---*
    // *--------- Leads List------------------------------*
    // *===============================================---*
    {
        path: '/leads/leads-list',
        name: 'leads-list',
        component: () => import('@/views/sales/leads/LeadList.vue'),
        meta: {
            pageTitle: 'Leads List',
            breadcrumb: [
                {
                    text: 'Leads List',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },



    // *===============================================---*
    // *--------- Leads Add------------------------------*
    // *===============================================---*
    {
        path: '/leads/leads-add',
        name: 'leads-add',
        component: () => import('@/views/sales/leads/Add/Main.vue'),
        meta: {
            pageTitle: 'Add Lead',
            breadcrumb: [
                {
                    text: 'Leads List',
                    to: '/leads/leads-list',
                },
                {
                    text: 'Add Lead',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },

  
    // *===============================================---*
    // *--------- Leads View------------------------------*
    // *===============================================---*

    {
        path: '/leads/leads-view/:id',
        name: 'leads-view',
        component: () => import('@/views/sales/leads/Edit/Main.vue'),
        meta: {
            pageTitle: 'View Lead',
            breadcrumb: [
                {
                    text: 'Leads List',
                    to: '/leads/leads-list',
                },
                {
                    text: 'View Lead',
                    active: true,
                },
            ],
            permission: 'Permissions.Sales.View'
        },
    },

   

]