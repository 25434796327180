import * as types from "./types";
import { 
    getModuleAxios,
  
} from "./services";

export async function getModuleAction({commit}) {
    commit(types.LOADING_MODULE, true);
    try {
        const {data} = await getModuleAxios();
        commit(types.GET_MODULE, data.result);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
   finally{
    commit(types.LOADING_MODULE, false);
   }
}


