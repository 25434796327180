export default [
    // *===============================================---*
    // *--------- labour  ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/Labour/Labour-list',
        name: 'labour-list',
        component: () => import('@/views/inventory/labour/ClientsList.vue'),
        meta: {
            pageTitle: 'Labour List',
            breadcrumb: [
                {
                    text: 'Labour List',
                    active: true,
                },
            ],
            permission: 'Permissions.Inventory.View'  
        },
    },
]