import * as types from "./types";
import { 
    getSalesReport,
    getFinishedGoodReport,
    getSemiReport,
    getRawReport,
    CostAnalysis,
    getProductionReport,
    getLeadTimeReport,
    getLeadTimeConversion
} from "./services";

export async function getSalesReportAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getSalesReport();
        commit(types.GET_SALESREPORT, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}


export async function getFinishedGoodAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getFinishedGoodReport();
        commit(types.GET_FINISHED, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}

export async function getSemiFinishedGoodAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getSemiReport();
        commit(types.GET_SEMI_FINISHED, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}


export async function getRawAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getRawReport();
        commit(types.GET_RAW, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}


export async function getCostAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await CostAnalysis();
        commit(types.GET_COST, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}

export async function getProductionAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getProductionReport();
        commit(types.GET_PRODUCTION, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}

export async function getLeadTimeAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getLeadTimeReport();
        commit(types.GET_LEADTIME, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}

export async function getLeadConversionAction({commit}) {
    commit(types.LOADING_REPORT, true);
    try {
        const {data} = await getLeadTimeConversion();
        commit(types.GET_LEAD_CONVERSION, data.result);
    }
    catch(e) {
        console.log(e);
    }
    commit(types.LOADING_REPORT, false);
}