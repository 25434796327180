import api from "@/api/api-basic";
import api2 from "@/api/api-change.js";


export async function getUserPermissionsAxios() {    
    return await api.get("personal/permissions");
}

export async function changePasswordAxios(data) {    
    return await api2.put("personal/change-password",data);
}

