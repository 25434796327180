import api from "@/api/api-v2-config";

export async function getSalesReport() {
    return await api.get("reports/salesreport");
}

export async function getFinishedGoodReport() {
    return await api.get("reports/finishedgoodsmovementreport");
}

export async function getSemiReport() {
    return await api.get("reports/semifinishedgoodsmovementreport");
}


export async function getRawReport() {
    return await api.get("reports/rawmaterialsmovementreport");
}


export async function CostAnalysis() {
    return await api.get("reports/productioncostanalysisreport")
}



export async function getProductionReport() {
    return await api.get("reports/productionreport")
}

export async function getLeadTimeReport() {
    return await api.get("reports/productionleadtimeanalysisreport")
}

export async function getLeadTimeConversion() {
    return await api.get("reports/leadconversionratereport")
}