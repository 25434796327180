import * as types from "./types";
import { 
    getRoleAxios, 
    deleteRoleAxios,
    postRoleAxios,
    putRoleAxios
} from "./services";

export async function getRoleListAction({commit}) {
    commit(types.LOADING_ROLE, true);
    try {
        const {data} = await getRoleAxios();
        commit(types.GET_ROLE_LIST, data.result);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

    commit(types.LOADING_ROLE, false);
    }
}

export async function removeRoleAction({commit}, payload) {
    commit(types.LOADING_ROLE, true);
    try {
        await deleteRoleAxios(payload);
        commit(types.REMOVE_ROLE, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

        commit(types.LOADING_ROLE, false);
        }
}

export async function addRoleAction({commit}, payload) {
    commit(types.LOADING_ROLE, true);
    try {
        await postRoleAxios(payload);
        commit(types.ADD_ROLE, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

        commit(types.LOADING_ROLE, false);
        }
}

export async function updateRoleAction({commit}, id, payload) {
    commit(types.LOADING_ROLE, true);
    try {
        await putRoleAxios(id, payload);        
        commit(types.UPDATE_ROLE, payload);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally {

        commit(types.LOADING_ROLE, false);
        }
}