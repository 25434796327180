import api from "@/api/api-v2-config";

export async function getEquipmentListAxios() {    
    return await api.get("equipments/equipments?pageNumber=1&pageSize=1000");
}

export async function deleteEquipmentAxios(id) {    
    return await api.delete("equipments/equipments?Id=" + id);
}

export async function postEquipmentAxios(equipment) {    
    return await api.post("equipments/equipments", equipment);
}

export async function putEquipmentAxios(equipment) {    
    return await api.put("equipments/equipments", equipment);
}