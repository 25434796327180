import api from "@/api/api-v2-config";

export async function getDeliveryNotesListAxios(id) {    
    return await api.get("deliverynotess/" + id + "/invoice");
}

export async function deleteDeliveryNotesAxios(id) {    
    return await api.delete("deliverynotes/" + id);
}

export async function postDeliveryNotesAxios(deliverynotes) {    
    return await api.post("deliverynotess", deliverynotes);
}

export async function putDeliveryNotesAxios(deliverynotes) {    
    return await api.put("deliverynotes", deliverynotes);
}

export async function getDeliveryNotesByIDAxios(id) {    
    return await api.get("deliverynotes" + id);
}