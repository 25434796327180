import api from "@/api/api-updated";

export async function getSalesOrderListAxios() {    
    return await api.get("orders?pageNumber=1&pageSize=10000");
}

export async function deleteSalesOrdersAxios(id) {    
    return await api.delete("orders?Id=" + id);
}

export async function postSalesOrdersAxios(salesorders) {    
    return await api.post("orders", salesorders);
}

export async function putSalesOrdersAxios(salesorders) {    
    return await api.put("orders", salesorders);
}

export async function getSalesOrdersByIDAxios(id) {    
    return await api.get("orders/" + id);
}

export async function getProductOrderAxios(productorder) {    
    return await api.get("orders/getproductorder", productorder);
}

export async function getInventoryCheckAxios(id) {    
    return await api.get("orders/" + id + "/checkinventory");
}