import { data } from "jquery";
import * as types from "./types";

const mutations = {
    [types.LOADING_DELIVERYNOTES](state, value) {
        state.loading = value;
    },
    [types.GET_DELIVERYNOTES_LIST](state, deliverynotes) {
        state.deliverynotes = deliverynotes;
    },
    [types.REMOVE_DELIVERYNOTES](state, id) {        
        state.deliverynotes = state.deliverynotes.filter((x) => x.id !== id);        
    },
    [types.ADD_DELIVERYNOTES](state, deliverynotes) {        
        state.deliverynotes.unshift(deliverynotes);
    },
    [types.UPDATE_DELIVERYNOTES](state, deliverynotes) {        
        const deliverynotesIndex = state.deliverynotes.findIndex((x) => x.id === deliverynotes.id);
        state.deliverynotes[deliverynotesIndex] = deliverynotes;        
    },
    [types.GET_DELIVERYNOTESBYID](state, deliverynotes) {        
        const deliverynotesIndex = state.deliverynotes.findIndex((x) => x.id === deliverynotes.id);
        state.deliverynotes[deliverynotesIndex] = deliverynotes;        
    }
};

export default mutations;