import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'


import authentication from './routes/authentication'
import marketing from './routes/marketing'
import lead from './routes/sale/lead'
import service from './routes/sale/service'
import customer from './routes/sale/customer'
import warehouse from './routes/warehouse'
import labour from './routes/inventory/labour'
import jobcard from './routes/production/jobcard'
import monitoring from './routes/production/monitoring'
import role from './routes/role'
import equipment from './routes/inventory/equipment'
import supplier from './routes/supplier'
import category from './routes/category'
import quotation from './routes/sale/quotation.js'
import salesorder from './routes/sale/salesorder.js'
import dashboard from './routes/dashboard'
import invoices from './routes/invoices'
import products from './routes/product'
import support from './routes/support'
import reports from './routes/reports'
import purchaseorder from './routes/inventory/purchaseorder.js'
import inventoryTransactions from './routes/inventory/inventoryTransactions'
import lowstock from './routes/inventory/lowstock.js'
import opportunity from './routes/sale/opportunity'
import complaint from './routes/sale/complaint'
import pipeline from './routes/sale/pipeline'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-godigital' } },
    ...authentication,
    ...salesorder,
    ...warehouse,
    ...marketing,
    ...lead,
    ...service,
    ...labour,
    ...customer,
    ...role,
    ...jobcard,
    ...monitoring,
    ...supplier,
    ...category,
    ...quotation,
    ...purchaseorder,
    ...dashboard,
    ...opportunity,
    ...complaint,
    ...invoices,
    ...products,
    ...reports,
    ...support,
    ...equipment,
    ...inventoryTransactions,
    ...lowstock,
    ...pipeline,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  if (to.meta.permission) {
    // Retrieve the user's permissions from local storage
    const userData = localStorage.getItem('userData')
    const permissions = JSON.parse(userData).permissions
 

    // Check if the user has the required permission
    if (!permissions.includes(to.meta.permission)) {
  
      return next({ name: 'misc-not-authorized' })
    }
  }


  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
