import api from "@/api/api-v2-config";

export async function getPushAxios() {
    return await api.get("pushorders/pushorder?pageNumber=1&pageSize=1000");
}

export async function deletePushAxios(id) {
    return await api.delete("pushorders/pushorder", id);
}

export async function postPushAxios(item) {
    return await api.post("pushorders/pushorder", item);
}

export async function putPushAxios(item) {
    return await api.put("pushorders/pushorder", item);
}

export async function getPushIdAxios(id){
    return await api.get("pushorders/pushorder/" + id);
}