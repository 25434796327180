import * as types from "./types";

import {
    getContactsListAxios,
    deleteContactsAxios,
    postContactsAxios,
    putContactsAxios,
    removeLeadContact,
    makePrimary,
} from "./services";

export async function getContactsListAction({ commit }) {
    commit(types.LOADING_CONTACTS, true);
    try {
        const { data } = await getContactsListAxios();
        commit(types.GET_CONTACTS_LIST, data.result);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_CONTACTS, false);
}


export async function deleteContactAction({ commit }, payload) {
    commit(types.LOADING_CONTACTS, true);
    try {
        await deleteContactsAxios(payload);
        commit(types.REMOVE_CONTACT, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_CONTACTS, false);
}

export async function addContactsAction({ commit }, payload) {
    commit(types.LOADING_CONTACTS, true);
    try {
        await postContactsAxios(payload);

        commit(types.ADD_CONTACT, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_CONTACTS, false);
}

export async function updateContactsAction({ commit }, payload) {
    commit(types.LOADING_CONTACTS, true);
    try {
        await putContactsAxios(payload);
        commit(types.UPDATE_CONTACT, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_CONTACTS, false);
}

export async function deleteLeadContact({ commit }, payload) {
    commit(types.LOADING_CONTACTS, true);
    try {
        await removeLeadContact(payload);
        commit(types.DELETE_CONTACT_LEAD, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_CONTACTS, false);
}

export async function makeContactPrimary({ commit }, payload) {
    commit(types.LOADING_CONTACTS, true);
    try {
        await makePrimary(payload);
        commit(types.PRIMARY_CONTACT, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_CONTACTS, false);
}