import * as types from "./types";
import {
    getTicketListAxios,
    deleteTicketAxios,
    postTicketAxios,
    putTicketAxios,
    getTicketIdAxios
} from "./services";

export async function getTicketListAction({ commit }) {
    commit(types.LOADING_TICKET, true);
    try {
        const { data } = await getTicketListAxios();
        commit(types.GET_TICKET_LIST, data.result.data);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_TICKET, false);
}

export async function getTicketIdAction({ commit }, payload) {
    commit(types.LOADING_TICKET, true);
    try {
        const { data } = await getTicketIdAxios(payload);
        commit(types.GET_TICKET_ID, data.result);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_TICKET, false);
}

export async function removeTicketAction({ commit }, payload) {
    commit(types.LOADING_TICKET, true);
    try {
        await deleteTicketAxios(payload);
        commit(types.REMOVE_TICKET, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_TICKET, false);
}

export async function addTicketAction({ commit }, payload) {
    commit(types.LOADING_TICKET, true);
    try {
        await postTicketAxios(payload);

        commit(types.ADD_TICKET, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_TICKET, false);
}

export async function updateTicketAction({ commit }, payload) {
    commit(types.LOADING_TICKET, true);
    try {
        await putTicketAxios(payload);
        commit(types.UPDATE_TICKET, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    commit(types.LOADING_TICKET, false);
}