import * as types from "./types";
import { 
    getUserPermissionsAxios, 
    changePasswordAxios

} from "./services";

export async function getPermissionsListAction({commit}) {
    commit(types.LOADING_PERMISSIONS, true);
    try {
        const {data} = await getUserPermissionsAxios();
        commit(types.GET_PERMISSIONS_LIST, data.result);
    }
    catch(e) {
        console.log(e);
        throw e;
    }
    finally
    {
        commit(types.LOADING_PERMISSIONS, false);
    }
    }
    

export async function changePasswordAction({commit}, payload) {
    commit(types.LOADING_PERMISSIONS, true);
    try {
        await changePasswordAxios(payload);
    }
    catch(e) {
        throw e;
    }
    finally
    {
        commit(types.LOADING_PERMISSIONS, false);
    }
   
}



