import api from "@/api/api-v2-config";

export async function getFileListAxios() {    
    return await api.get("files");
}

export async function deleteFileAxios(id) {    
    return await api.delete("files", id);
}

export async function postFileAxios(files) {    
    return await api.post("files", files);
}

export async function putFileAxios(files) {    
    return await api.put("files", files);
}