import * as types from "./types";
import {
    getItemCategoryListAxios,
    deleteItemCategoryAxios,
    postItemCategoryAxios,
    putItemCategoryAxios
} from "./services";

export async function getItemCategoryListAction({ commit }) {
    commit(types.LOADING_ITEMCATEGORY, true);
    try {
        const { data } = await getItemCategoryListAxios();
        commit(types.GET_ITEMCATEGORY_LIST, data.result.data);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMCATEGORY, false);
    }
}

export async function removeItemCategoryAction({ commit }, payload) {

    commit(types.LOADING_ITEMCATEGORY, true);
    try {
        await deleteItemCategoryAxios(payload);
        commit(types.REMOVE_ITEMCATEGORY, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMCATEGORY, false);
    }
}

export async function addItemCategoryAction({ commit }, payload) {
    commit(types.LOADING_ITEMCATEGORY, true);
    try {
        await postItemCategoryAxios(payload);

        commit(types.ADD_ITEMCATEGORY, payload);
    }
    catch (e) {
        throw e;
    }
    finally {
        commit(types.LOADING_ITEMCATEGORY, false);
    }
}

export async function updateItemCategoryAction({ commit }, payload) {
    commit(types.LOADING_ITEMCATEGORY, true);
    try {
        await putItemCategoryAxios(payload);
        commit(types.UPDATE_ITEMCATEGORY, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }

    finally {
        commit(types.LOADING_ITEMCATEGORY, false);
    }

}