import * as types from "./types";
import {
    getTransactionListAxios,
    getTransactionByIDAxios,
    deleteTransactionAxios,
    postTransactionAxios,
    putTransactionAxios    
} from "./services";

export async function getTransactionListAction({ commit }) {
    commit(types.LOADING_INVENTORY_TRANSACTION, true);
    try {
        const { data } = await getTransactionListAxios();
        commit(types.GET_INVENTORY_TRANSACTION_LIST, data.result.data);
    }
    catch (e) {
        console.log(e);
    }
    finally {
        commit(types.LOADING_INVENTORY_TRANSACTION, false);
    }
}

export async function getTransactionByIdAction({ commit }, payload) {
    commit(types.LOADING_INVENTORY_TRANSACTION, true);
    try {
        const { data } = await getTransactionByIDAxios(payload);
        return data.result;
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_INVENTORY_TRANSACTION, false);
    }
}

export async function removeTransactionAction({ commit }, payload) {
    commit(types.LOADING_INVENTORY_TRANSACTION, true);
    try {
        await deleteTransactionAxios(payload);
        commit(types.REMOVE_INVENTORY_TRANSACTION, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_INVENTORY_TRANSACTION, false);
    }
}

export async function addTransactionAction({ commit }, payload) {
    commit(types.LOADING_INVENTORY_TRANSACTION, true);
    try {
        const { data } = await postTransactionAxios(payload);
        payload.id = data.result.id;        
        commit(types.ADD_INVENTORY_TRANSACTION, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_INVENTORY_TRANSACTION, false);
    }
}

export async function updateTransactionAction({ commit }, payload) {
    commit(types.LOADING_INVENTORY_TRANSACTION, true);
    try {
        await putTransactionAxios(payload);
        commit(types.UPDATE_INVENTORY_TRANSACTION, payload);
    }
    catch (e) {
        console.log(e);
        throw e;
    }
    finally {
        commit(types.LOADING_INVENTORY_TRANSACTION, false);
    }
}