import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  invoices: [
    {
      id: 4987,
      issuedDate: '13 Dec 2019',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson',
      },
      service: 'Software Development',
      total: 3428,
      avatar: '',
      invoiceStatus: 'Paid',
      status: 0,
      balance: '$724',
      sotype: 'Fixed',
      dueDate: '23 Apr 2019',
    },
    {
      id: 4988,
      issuedDate: '17 Jul 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'Mccann LLC and Sons',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        
        contact: '(226) 204-8287',
        name: 'Stephanie Burns',
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      status: 0,
      sotype: 'Variable',
      avatar: require('@/assets/images/avatars/10-small.png'),
      invoiceStatus: 'Downloaded',
      balance: 0,
      dueDate: '15 Dec 2019',
    },
    {
      id: 4989,
      issuedDate: '19 Oct 2019',
      client: {
        address: '5345 Robert Squares',
        company: 'Leonard-Garcia and Sons',
        companyEmail: 'smithtiffany@powers.com',
        country: 'Denmark',
        contact: '(955) 676-1076',
        name: 'Tony Herrera',
      },
      service: 'Unlimited Extended License',
      total: 3719,
      avatar: require('@/assets/images/avatars/1-small.png'),
      invoiceStatus: 'Paid',
      sotype: 'Recurring',
      status: 0,
      balance: 0,
      dueDate: '03 Nov 2019',
    },
    {
      id: 4990,
      issuedDate: '06 Mar 2020',
      client: {
        address: '19022 Clark Parks Suite 149',
        company: 'Smith, Miller and Henry LLC',
        companyEmail: 'mejiageorge@lee-perez.com',
        country: 'Cambodia',
        contact: '(832) 323-6914',
        name: 'Kevin Patton',
      },
      service: 'Software Development',
      total: 4749,
      avatar: require('@/assets/images/avatars/9-small.png'),
      invoiceStatus: 'Sent',
      balance: 0,
      sotype: 'One Off',
      status: 100,
      dueDate: '11 Feb 2020',
    },
    {
      id: 4991,
      issuedDate: '08 Feb 2020',
      client: {
        address: '8534 Saunders Hill Apt. 583',
        company: 'Garcia-Cameron and Sons',
        companyEmail: 'brandon07@pierce.com',
        country: 'Martinique',
        contact: '(970) 982-3353',
        name: 'Mrs. Julie Donovan MD',
      },
      service: 'UI/UX Design & Development',
      total: 4056,
      avatar: require('@/assets/images/avatars/10-small.png'),
      invoiceStatus: 'Draft',
      balance: '$815',
      status: 100,
      sotype: 'One Off',
      dueDate: '30 Jun 2019',
    },
    {
      id: 4992,
      issuedDate: '26 Aug 2019',
      client: {
        address: '661 Perez Run Apt. 778',
        company: 'Burnett-Young PLC',
        companyEmail: 'guerrerobrandy@beasley-harper.com',
        country: 'Botswana',
        contact: '(511) 938-9617',
        name: 'Amanda Phillips',
      },
      service: 'UI/UX Design & Development',
      total: 2771,
      avatar: '',
      invoiceStatus: 'Paid',
      sotype: 'Variable',
      status: 100,
      balance: 0,
      dueDate: '24 Jun 2019',
    },
    {
      id: 4993,
      issuedDate: '17 Sep 2019',
      client: {
        address: '074 Long Union',
        company: 'Wilson-Lee LLC',
        companyEmail: 'williamshenry@moon-smith.com',
        country: 'Montserrat',
        contact: '(504) 859-2893',
        name: 'Christina Collier',
      },
      service: 'UI/UX Design & Development',
      total: 2713,
      avatar: '',
      invoiceStatus: 'Draft',
      balance: '$407',
      dueDate: '22 Nov 2019',
      sotype: 'Variable',
    },
    {
      id: 4994,
      issuedDate: '11 Feb 2020',
      client: {
        address: '5225 Ford Cape Apt. 840',
        company: 'Schwartz, Henry and Rhodes Group',
        companyEmail: 'margaretharvey@russell-murray.com',
        country: 'Oman',
        contact: '(758) 403-7718',
        name: 'David Flores',
      },
      service: 'Template Customization',
      total: 4309,
      avatar: require('@/assets/images/avatars/9-small.png'),
      invoiceStatus: 'Paid',
      sotype: 'Fixed',
      balance: '-$205',
      status: 100,
      dueDate: '10 Feb 2020',
    },
    {
      id: 4995,
      issuedDate: '26 Jan 2020',
      client: {
        address: '23717 James Club Suite 277',
        company: 'Henderson-Holder PLC',
        companyEmail: 'dianarodriguez@villegas.com',
        country: 'Cambodia',
        contact: '(292) 873-8254',
        name: 'Valerie Perez',
      },
      service: 'Software Development',
      total: 3367,
      avatar: require('@/assets/images/avatars/2-small.png'),
      invoiceStatus: 'Downloaded',
      balance: 0,
      status: 100,
      sotype: 'Fixed',
      dueDate: '24 Dec 2019',
    },
    {
      id: 4996,
      issuedDate: '15 Jan 2020',
      client: {
        address: '4528 Myers Gateway',
        company: 'Page-Wise PLC',
        companyEmail: 'bwilson@norris-brock.com',
        country: 'Guam',
        contact: '(956) 803-2008',
        name: 'Susan Dickerson',
      },
      service: 'Software Development',
      total: 4776,
      avatar: require('@/assets/images/avatars/9-small.png'),
      invoiceStatus: 'Downloaded',
      sotype: 'Fixed',
      status: 100,
      balance: '$305',
      dueDate: '02 Jun 2019',
    },
    {
      id: 5033,
      issuedDate: '12 Jul 2019',
      client: {
        address: '075 Smith Views',
        company: 'Jenkins-Rosales Inc',
        companyEmail: 'calvin07@joseph-edwards.org',
        country: 'Colombia',
        contact: '(895) 401-4255',
        name: 'Lori Wells',
      },
      service: 'Template Customization',
      total: 2869,
      avatar: require('@/assets/images/avatars/1-small.png'),
      invoiceStatus: 'Partial Payment',
      balance: 0,
      status: 100,
      dueDate: '22 Mar 2020',
      sotype: 'Fixed',
    },
    {
      id: 5034,
      issuedDate: '10 Jul 2019',
      client: {
        address: '2577 Pearson Overpass Apt. 314',
        company: 'Mason-Reed PLC',
        companyEmail: 'eric47@george-castillo.com',
        country: 'Paraguay',
        contact: '(602) 336-9806',
        name: 'Tammy Sanchez',
      },
      service: 'Unlimited Extended License',
      total: 4836,
      sotype: 'Fixed',
      status: 100,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: 0,
      dueDate: '22 Oct 2019',
    },
   
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/proposal/proposals').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const filteredData = data.invoices.filter(
    invoice =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (invoice.client.companyEmail.toLowerCase().includes(queryLowered) ||
        invoice.client.name.toLowerCase().includes(queryLowered)) &&
      invoice.invoiceStatus === (status || invoice.invoiceStatus),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      invoices: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
mock.onGet(/\/apps\/proposal\/proposals\/\d+/).reply(config => {
  // Get event id from URL
  let invoiceId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex(e => e.id === invoiceId)
  const invoice = data.invoices[invoiceIndex]
  const responseData = {
    invoice,
    paymentDetails: {
      totalDue: 'Rs 12,110.55',
      bankName: 'MCB',
      country: 'Mauritius',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (invoice) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/proposal/clients').reply(() => {
  const clients = data.invoices.map(invoice => invoice.client)
  return [200, clients.slice(0, 5)]
})
