import * as types from "./types";

const mutations = {
    [types.LOADING_ITEMUOM](state, value) {
        state.loading = value;
    },
    [types.GET_ITEMUOM_LIST](state, itemuom) {
        state.itemuom = itemuom;
    },
    [types.REMOVE_ITEMUOM](state, id) {        
        state.itemuom = state.itemuom.filter((x) => x.id !== id);        
    },
    [types.ADD_ITEMUOM](state, item) {        
        state.itemuom.unshift(item);
    },
    [types.UPDATE_ITEMUOM](state, item) {        
        const itemUOMIndex = state.itemuom.findIndex((x) => x.id === item.id);
        state.itemuom[itemUOMIndex] = item;        
    }
};

export default mutations;