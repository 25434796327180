export default [
    {
        path: '/role/tenant/list',
        name: 'role-tenant',
        component: () => import('@/views/rolemanagement/tenants/TenantList.vue'),
        meta: {
            pageTitle: 'Company List',
            breadcrumb:[
                {
                    text:'Company List',
                    active:true,
                },
            ],
            permission: 'Permissions.Tenants.View'
        },
    },
    {
        path: '/role/list',
        name: 'role-list',
        component: () => import('@/views/rolemanagement/roles/roles.vue'),
        meta: {
            pageTitle: 'User Roles',
            breadcrumb:[
                {
                    text:'User Roles',
                    active:true,
                },
            ],   
            permission: 'Permissions.Roles.View'         
        },
    },

    {
        path: '/role/users',
        name: 'role-users',
        component: () => import('@/views/rolemanagement/users/UsersList.vue'),
        meta: {
            pageTitle: 'Users List',
            breadcrumb:[
                {
                    text:'Users List',
                    active:true,
                },
            ],    
            permission: 'Permissions.Users.View'             
        },
    },
    {
        path: '/role/user/view/:id',
        name: 'role-view-user',
        component: () => import('@/views/rolemanagement/users/ViewUser.vue'),
        meta: {
            pageTitle: 'View User',
            breadcrumb:[
                {
                    text:'Users List',
                    to: '/role/users',
                },
                {
                    text:'View User',
                    active:true,
                },
            ], 
            permission: 'Permissions.Users.View'            
        },
    },
]