export default [
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
        path: '/reports/sales',
        name: 'reports-sales',
        component: () => import('@/views/reports/sales/main.vue'),
        meta: {
            pageTitle: 'Sales Reports',
            breadcrumb: [
                {
                    text: 'Sales Reports',
                    active:true,
                },
            ],
        },
    },

    {
        path: '/reports/inventory',
        name: 'reports-inventory',
        component: () => import('@/views/reports/inventory/main.vue'),
        meta: {
            pageTitle: 'Inventory Reports',
            breadcrumb: [
                {
                    text: 'Inventory Reports',
                    active:true,
                },
            ],
        },
    },

    {
        path: '/reports/marketing',
        name: 'reports-marketing',
        component: () => import('@/views/reports/marketing/main.vue'),
        meta: {
            pageTitle: 'Marketing Reports',
            breadcrumb: [
                {
                    text: 'Marketing Reports',
                    active:true,
                },
            ],
        },
    },

    {
        path: '/reports/production',
        name: 'reports-production',
        component: () => import('@/views/reports/production/main.vue'),
        meta: {
            pageTitle: 'Production Reports',
            breadcrumb: [
                {
                    text: 'Production Reports',
                    active:true,
                },
            ],
        },
    },

]